/**
 * @generated SignedSource<<6e764ca740008e2bbfb5894e65d842ed>>
 * @relayHash 20172b6b34bfee3b53fb458e18a1018f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 20172b6b34bfee3b53fb458e18a1018f

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingGraderQuery$variables = {
  examId: string;
  skipCourse: boolean;
};
export type gradingGraderQuery$data = {
  readonly exam: {
    readonly course?: {
      readonly id: string;
      readonly title: string;
    };
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"gradingBeginGrading" | "gradingMyGrading">;
  };
};
export type gradingGraderQuery = {
  response: gradingGraderQuery$data;
  variables: gradingGraderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipCourse"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "condition": "skipCourse",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qnum",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pnum",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GradingLockEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingLock",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingGraderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "gradingBeginGrading"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "gradingMyGrading"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingGraderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersionConnection",
            "kind": "LinkedField",
            "name": "examVersions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamVersion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completionSummary",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QpPair",
                        "kind": "LinkedField",
                        "name": "qpPairs",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "inProgress",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "graderCurrentUser",
                            "value": true
                          }
                        ],
                        "concreteType": "GradingLockConnection",
                        "kind": "LinkedField",
                        "name": "gradingLocks",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": "gradingLocks(graderCurrentUser:true)"
                      },
                      {
                        "alias": "finished",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "completedByCurrentUser",
                            "value": true
                          }
                        ],
                        "concreteType": "GradingLockConnection",
                        "kind": "LinkedField",
                        "name": "gradingLocks",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": "gradingLocks(completedByCurrentUser:true)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "20172b6b34bfee3b53fb458e18a1018f",
    "metadata": {},
    "name": "gradingGraderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0ce32fe02c12a9efd4ce1f6db6063f43";

export default node;
