/**
 * @generated SignedSource<<0cad78c872a26b878d4309a42d24a28f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingBeginGrading$data = {
  readonly examVersions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completionSummary: any;
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "gradingBeginGrading";
};
export type gradingBeginGrading$key = {
  readonly " $data"?: gradingBeginGrading$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingBeginGrading">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingBeginGrading",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamVersionConnection",
      "kind": "LinkedField",
      "name": "examVersions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completionSummary",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "1a57cb922595a3e84616a79a5d584cea";

export default node;
