/**
 * @generated SignedSource<<1a08fdf905c23fbf0f4a3cc4b49991fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamMessages_all$data = {
  readonly examAnnouncements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly id: string;
      } | null;
    } | null> | null;
  };
  readonly id: string;
  readonly myRegistration: {
    readonly examVersion: {
      readonly id: string;
      readonly versionAnnouncements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly body: string;
            readonly createdAt: string;
            readonly id: string;
          } | null;
        } | null> | null;
      };
    };
    readonly id: string;
    readonly messages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly body: string;
          readonly createdAt: string;
          readonly id: string;
        } | null;
      } | null> | null;
    };
    readonly room: {
      readonly id: string;
      readonly roomAnnouncements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly body: string;
            readonly createdAt: string;
            readonly id: string;
          } | null;
        } | null> | null;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "ExamMessages_all";
};
export type ExamMessages_all$key = {
  readonly " $data"?: ExamMessages_all$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamMessages_all">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "body",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "examAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "myRegistration",
          "room",
          "roomAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "myRegistration",
          "examVersion",
          "versionAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "myRegistration",
          "messages"
        ]
      }
    ]
  },
  "name": "ExamMessages_all",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "examAnnouncements",
      "args": null,
      "concreteType": "ExamAnnouncementConnection",
      "kind": "LinkedField",
      "name": "__ExamMessages_examAnnouncements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamAnnouncementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamAnnouncement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "myRegistration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Room",
          "kind": "LinkedField",
          "name": "room",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "roomAnnouncements",
              "args": null,
              "concreteType": "RoomAnnouncementConnection",
              "kind": "LinkedField",
              "name": "__ExamMessages_roomAnnouncements_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoomAnnouncementEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoomAnnouncement",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersion",
          "kind": "LinkedField",
          "name": "examVersion",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "versionAnnouncements",
              "args": null,
              "concreteType": "VersionAnnouncementConnection",
              "kind": "LinkedField",
              "name": "__ExamMessages_versionAnnouncements_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VersionAnnouncementEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VersionAnnouncement",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "messages",
          "args": null,
          "concreteType": "MessageConnection",
          "kind": "LinkedField",
          "name": "__ExamMessages_messages_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MessageEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Message",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "89eb2ec0bb2ddc385da648004cd86568";

export default node;
