/**
 * @generated SignedSource<<462151b762b5b0fe25bcc4b876c9e6d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
export type RubricDirection = "credit" | "deduction";
export type RubricVariant = "all" | "any" | "none" | "one";
import { FragmentRefs } from "relay-runtime";
export type showExamViewer$data = {
  readonly answers: any;
  readonly dbQuestions: ReadonlyArray<{
    readonly id: string;
    readonly parts: ReadonlyArray<{
      readonly bodyItems: ReadonlyArray<{
        readonly id: string;
        readonly rubrics: ReadonlyArray<{
          readonly id: string;
        }>;
      }>;
      readonly id: string;
      readonly rubrics: ReadonlyArray<{
        readonly id: string;
      }>;
    }>;
    readonly rubrics: ReadonlyArray<{
      readonly id: string;
    }>;
  }>;
  readonly dbReferences: ReadonlyArray<{
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly files: any;
  readonly id: string;
  readonly instructions: {
    readonly type: HtmlTag;
    readonly value: string;
  };
  readonly rootRubric?: {
    readonly " $fragmentSpreads": FragmentRefs<"ShowRubricKey">;
  } | null;
  readonly rubrics: ReadonlyArray<{
    readonly description: {
      readonly type: HtmlTag;
      readonly value: string;
    } | null;
    readonly id: string;
    readonly order: number | null;
    readonly points: number | null;
    readonly rubricPreset: {
      readonly direction: RubricDirection;
      readonly id: string;
      readonly label: string | null;
      readonly mercy: number | null;
      readonly presetComments: ReadonlyArray<{
        readonly graderHint: string;
        readonly id: string;
        readonly label: string | null;
        readonly order: number | null;
        readonly points: number;
        readonly studentFeedback: string | null;
      }>;
    } | null;
    readonly subsections: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly type: RubricVariant;
  }> | null;
  readonly " $fragmentSpreads": FragmentRefs<"DisplayQuestions">;
  readonly " $fragmentType": "showExamViewer";
};
export type showExamViewer$key = {
  readonly " $data"?: showExamViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"showExamViewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rubrics",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "withRubric"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "showExamViewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answers",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplayQuestions"
    },
    {
      "condition": "withRubric",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rootRubric",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowRubricKey"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "dbReferences",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "instructions",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "dbQuestions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Part",
          "kind": "LinkedField",
          "name": "parts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BodyItem",
              "kind": "LinkedField",
              "name": "bodyItems",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "files",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "rubrics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Html",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RubricPreset",
          "kind": "LinkedField",
          "name": "rubricPreset",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "direction",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mercy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PresetComment",
              "kind": "LinkedField",
              "name": "presetComments",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v7/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "graderHint",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "studentFeedback",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "subsections",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};
})();

(node as any).hash = "811a590d974381841d5263a210bbe128";

export default node;
