/**
 * @generated SignedSource<<69dc0695724a0933bd95c3f4dda4f11f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type admin_publishGrades$data = {
  readonly graded: boolean;
  readonly id: string;
  readonly " $fragmentType": "admin_publishGrades";
};
export type admin_publishGrades$key = {
  readonly " $data"?: admin_publishGrades$data;
  readonly " $fragmentSpreads": FragmentRefs<"admin_publishGrades">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "admin_publishGrades",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "graded",
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "88bc3f21820354ac2d8290659431038f";

export default node;
