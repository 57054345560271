/**
 * @generated SignedSource<<96252916bb18c582ce4da2d07785f667>>
 * @relayHash fae186437cad5cae5ac7de3953ddcb93
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fae186437cad5cae5ac7de3953ddcb93

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type examsAnomalyDestroyedSubscription$variables = {
  examId: string;
};
export type examsAnomalyDestroyedSubscription$data = {
  readonly anomalyWasDestroyed: {
    readonly deletedId: string;
  };
};
export type examsAnomalyDestroyedSubscription = {
  response: examsAnomalyDestroyedSubscription$data;
  variables: examsAnomalyDestroyedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "examId",
        "variableName": "examId"
      }
    ],
    "concreteType": "AnomalyWasDestroyedPayload",
    "kind": "LinkedField",
    "name": "anomalyWasDestroyed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsAnomalyDestroyedSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsAnomalyDestroyedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "fae186437cad5cae5ac7de3953ddcb93",
    "metadata": {},
    "name": "examsAnomalyDestroyedSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "7ffa6ce46b1a248b6e45efd8f3df2247";

export default node;
