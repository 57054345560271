/**
 * @generated SignedSource<<10e3f459ecb2bdc0e37e38342559cacb>>
 * @relayHash 81b51e0797c884cf75a177deb196017b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 81b51e0797c884cf75a177deb196017b

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
export type ChangePartDetailsInput = {
  clientMutationId?: string | null;
  description?: HtmlInput | null;
  extraCredit?: boolean | null;
  name?: HtmlInput | null;
  partId: string;
  points?: number | null;
  references?: ReadonlyArray<ReferenceInput> | null;
  updateDescription?: boolean | null;
  updateExtraCredit?: boolean | null;
  updateName?: boolean | null;
  updatePoints?: boolean | null;
  updateReferences?: boolean | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type ReferenceInput = {
  id?: string | null;
  path: string;
  type: ReferenceType;
};
export type PartChangeMutation$variables = {
  input: ChangePartDetailsInput;
};
export type PartChangeMutation$data = {
  readonly changePartDetails: {
    readonly part: {
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly extraCredit: boolean;
      readonly id: string;
      readonly name: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly points: number;
      readonly references: ReadonlyArray<{
        readonly id: string;
        readonly path: string;
        readonly type: ReferenceType;
      }>;
    };
  } | null;
};
export type PartChangeMutation = {
  response: PartChangeMutation$data;
  variables: PartChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangePartDetailsPayload",
    "kind": "LinkedField",
    "name": "changePartDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Part",
        "kind": "LinkedField",
        "name": "part",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reference",
            "kind": "LinkedField",
            "name": "references",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extraCredit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "points",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartChangeMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartChangeMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "81b51e0797c884cf75a177deb196017b",
    "metadata": {},
    "name": "PartChangeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6141f0b8cf973b031f3c4be67d3feb94";

export default node;
