/**
 * @generated SignedSource<<acd46bfc6f655466ed4870838edca54a>>
 * @relayHash 49962d772b15a443b133edc973c1eb5d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 49962d772b15a443b133edc973c1eb5d

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestGradingLockInput = {
  clientMutationId?: string | null;
  pnum: number;
  qnum: number;
  registrationId: string;
  steal?: boolean | null;
};
export type PartRequestGradingLockMutation$variables = {
  input: RequestGradingLockInput;
};
export type PartRequestGradingLockMutation$data = {
  readonly requestGradingLock: {
    readonly acquired: boolean;
    readonly currentOwner: {
      readonly displayName: string;
      readonly id: string;
    };
  } | null;
};
export type PartRequestGradingLockMutation = {
  response: PartRequestGradingLockMutation$data;
  variables: PartRequestGradingLockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestGradingLockPayload",
    "kind": "LinkedField",
    "name": "requestGradingLock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acquired",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentOwner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartRequestGradingLockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartRequestGradingLockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "49962d772b15a443b133edc973c1eb5d",
    "metadata": {},
    "name": "PartRequestGradingLockMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b6cba96cc409afd3565fd8581c140f30";

export default node;
