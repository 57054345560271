/**
 * @generated SignedSource<<bb1100b8171191b74024f12edb673909>>
 * @relayHash 58b6db09376e853af2b413e6753e0f39
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 58b6db09376e853af2b413e6753e0f39

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ExamMessagesNewAnnouncementSubscription$variables = {
  examId: string;
};
export type ExamMessagesNewAnnouncementSubscription$data = {
  readonly examAnnouncementWasSent: {
    readonly examAnnouncement: {
      readonly body: string;
      readonly createdAt: string;
      readonly id: string;
    };
    readonly examAnnouncementsEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type ExamMessagesNewAnnouncementSubscription = {
  response: ExamMessagesNewAnnouncementSubscription$data;
  variables: ExamMessagesNewAnnouncementSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "examId",
        "variableName": "examId"
      }
    ],
    "concreteType": "ExamAnnouncementWasSentPayload",
    "kind": "LinkedField",
    "name": "examAnnouncementWasSent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamAnnouncement",
        "kind": "LinkedField",
        "name": "examAnnouncement",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamAnnouncementEdge",
        "kind": "LinkedField",
        "name": "examAnnouncementsEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamAnnouncement",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamMessagesNewAnnouncementSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamMessagesNewAnnouncementSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "58b6db09376e853af2b413e6753e0f39",
    "metadata": {},
    "name": "ExamMessagesNewAnnouncementSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "b2aa2fb9f265f009f0d8d3820eff2f27";

export default node;
