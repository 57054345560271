/**
 * @generated SignedSource<<8726b989897d83bb814c46cfc3b7de55>>
 * @relayHash d00fe4fa33b6f7a5606272222e9edcdb
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d00fe4fa33b6f7a5606272222e9edcdb

import { ConcreteRequest, Query } from 'relay-runtime';
export type submissionsExportStudentAnswersQuery$variables = {
  regId: string;
};
export type submissionsExportStudentAnswersQuery$data = {
  readonly registration: {
    readonly currentAnswers: any | null;
    readonly id: string;
  } | null;
};
export type submissionsExportStudentAnswersQuery = {
  response: submissionsExportStudentAnswersQuery$data;
  variables: submissionsExportStudentAnswersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "regId"
      }
    ],
    "concreteType": "Registration",
    "kind": "LinkedField",
    "name": "registration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentAnswers",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsExportStudentAnswersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsExportStudentAnswersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d00fe4fa33b6f7a5606272222e9edcdb",
    "metadata": {},
    "name": "submissionsExportStudentAnswersQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dec8b04b66f1d816ef2e180103a86c5e";

export default node;
