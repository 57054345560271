/**
 * @generated SignedSource<<241200dc99ed619e0b104901fdd475e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type grading_showOne$data = {
  readonly currentAnswers: any | null;
  readonly currentGrading: any | null;
  readonly examVersion: {
    readonly answers: any;
    readonly dbQuestions: ReadonlyArray<{
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly extraCredit: boolean;
      readonly name: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly parts: ReadonlyArray<{
        readonly extraCredit: boolean;
        readonly id: string;
        readonly name: {
          readonly value: string;
        } | null;
        readonly points: number;
        readonly " $fragmentSpreads": FragmentRefs<"PartShow">;
      }>;
      readonly references: ReadonlyArray<{
        readonly path: string;
        readonly type: ReferenceType;
      }>;
    }>;
    readonly files: any;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"gradingRubric">;
  };
  readonly gradingChecks: ReadonlyArray<{
    readonly bnum: number;
    readonly id: string;
    readonly pnum: number;
    readonly points: number | null;
    readonly qnum: number;
  }> | null;
  readonly gradingComments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly bnum: number;
        readonly id: string;
        readonly message: string;
        readonly pnum: number;
        readonly points: number;
        readonly presetComment: {
          readonly graderHint: string;
          readonly id: string;
          readonly points: number;
          readonly studentFeedback: string | null;
        } | null;
        readonly qnum: number;
      } | null;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "grading_showOne";
};
export type grading_showOne$key = {
  readonly " $data"?: grading_showOne$data;
  readonly " $fragmentSpreads": FragmentRefs<"grading_showOne">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qnum",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pnum",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bnum",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraCredit",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "gradingComments"
        ]
      }
    ]
  },
  "name": "grading_showOne",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentGrading",
      "storageKey": null
    },
    {
      "alias": "gradingComments",
      "args": null,
      "concreteType": "GradingCommentConnection",
      "kind": "LinkedField",
      "name": "__Registration_gradingComments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GradingCommentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GradingComment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PresetComment",
                  "kind": "LinkedField",
                  "name": "presetComment",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "graderHint",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "studentFeedback",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GradingCheck",
      "kind": "LinkedField",
      "name": "gradingChecks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamVersion",
      "kind": "LinkedField",
      "name": "examVersion",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gradingRubric"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Question",
          "kind": "LinkedField",
          "name": "dbQuestions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Html",
              "kind": "LinkedField",
              "name": "name",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Html",
              "kind": "LinkedField",
              "name": "description",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Reference",
              "kind": "LinkedField",
              "name": "references",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Part",
              "kind": "LinkedField",
              "name": "parts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Html",
                  "kind": "LinkedField",
                  "name": "name",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v4/*: any*/),
                (v8/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PartShow"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "files",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Registration",
  "abstractKey": null
};
})();

(node as any).hash = "da549ad9d33fa7e07a1b7577b9bf4bd1";

export default node;
