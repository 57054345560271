/**
 * @generated SignedSource<<ae03f3c1dac7843c63244d176264bd94>>
 * @relayHash 7ffedd129022d91dcf837d5d76abede5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7ffedd129022d91dcf837d5d76abede5

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangePresetCommentDetailsInput = {
  clientMutationId?: string | null;
  graderHint?: string | null;
  label?: string | null;
  points?: number | null;
  presetCommentId: string;
  studentFeedback?: string | null;
  updateGraderHint?: boolean | null;
  updateLabel?: boolean | null;
  updatePoints?: boolean | null;
  updateStudentFeedback?: boolean | null;
};
export type RubricChangePresetCommentLabelMutation$variables = {
  input: ChangePresetCommentDetailsInput;
};
export type RubricChangePresetCommentLabelMutation$data = {
  readonly changePresetCommentDetails: {
    readonly presetComment: {
      readonly id: string;
      readonly label: string | null;
    };
  } | null;
};
export type RubricChangePresetCommentLabelMutation = {
  response: RubricChangePresetCommentLabelMutation$data;
  variables: RubricChangePresetCommentLabelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangePresetCommentDetailsPayload",
    "kind": "LinkedField",
    "name": "changePresetCommentDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PresetComment",
        "kind": "LinkedField",
        "name": "presetComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangePresetCommentLabelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangePresetCommentLabelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7ffedd129022d91dcf837d5d76abede5",
    "metadata": {},
    "name": "RubricChangePresetCommentLabelMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "49337a17af3390ba88cead1e48420ae6";

export default node;
