/**
 * @generated SignedSource<<84d572cb8b7327b9133a2e79582af4f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exams_pins$data = {
  readonly id: string;
  readonly registrations: ReadonlyArray<{
    readonly currentPin: string | null;
    readonly id: string;
    readonly pinValidated: boolean;
  }>;
  readonly " $fragmentType": "exams_pins";
};
export type exams_pins$key = {
  readonly " $data"?: exams_pins$data;
  readonly " $fragmentSpreads": FragmentRefs<"exams_pins">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./RegistrationPinRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "exams_pins",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentPin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinValidated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "be1f89fcdbbe8096175dbb378af222e7";

export default node;
