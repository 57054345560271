/**
 * @generated SignedSource<<c394eb016d688805e43186ebb7484606>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PolicyExemption = "IGNORE_LOCKDOWN" | "IGNORE_PIN" | "TOLERATE_WINDOWED";
import { FragmentRefs } from "relay-runtime";
export type PreStart$data = {
  readonly myRegistration: {
    readonly anomalous: boolean;
    readonly lastSnapshot: string | null;
    readonly over: boolean;
    readonly pinValidated: boolean;
    readonly policyExemptions: ReadonlyArray<PolicyExemption>;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"AnomalousMessaging">;
  readonly " $fragmentType": "PreStart";
};
export type PreStart$key = {
  readonly " $data"?: PreStart$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreStart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreStart",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnomalousMessaging"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "myRegistration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "anomalous",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "over",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastSnapshot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "policyExemptions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinValidated",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "828f5bca19eb9b5662e91d69bbef3abd";

export default node;
