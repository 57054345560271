/**
 * @generated SignedSource<<6f231f4ef7190b7226bab5f6ab52c38e>>
 * @relayHash 9f0f6e211efeca2776982f26b8564220
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9f0f6e211efeca2776982f26b8564220

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PublishGradesInput = {
  clientMutationId?: string | null;
  examId: string;
  published: boolean;
};
export type adminPublishGradesMutation$variables = {
  input: PublishGradesInput;
};
export type adminPublishGradesMutation$data = {
  readonly publishGrades: {
    readonly count: number;
    readonly published: boolean;
  } | null;
};
export type adminPublishGradesMutation = {
  response: adminPublishGradesMutation$data;
  variables: adminPublishGradesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PublishGradesPayload",
    "kind": "LinkedField",
    "name": "publishGrades",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminPublishGradesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminPublishGradesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9f0f6e211efeca2776982f26b8564220",
    "metadata": {},
    "name": "adminPublishGradesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "634825cfc1543277cc6c8d502330da36";

export default node;
