/**
 * @generated SignedSource<<bb6974c1d808474384b9546319800c01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type roomsIndex$data = {
  readonly id: string;
  readonly rooms: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly proctorRegistrations: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly registrations: ReadonlyArray<{
      readonly id: string;
    }>;
  }>;
  readonly " $fragmentType": "roomsIndex";
};
export type roomsIndex$key = {
  readonly " $data"?: roomsIndex$data;
  readonly " $fragmentSpreads": FragmentRefs<"roomsIndex">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "roomsIndex",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "rooms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Registration",
          "kind": "LinkedField",
          "name": "registrations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProctorRegistration",
          "kind": "LinkedField",
          "name": "proctorRegistrations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "a81546b4a83674f246956032e78e6f1d";

export default node;
