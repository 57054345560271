/**
 * @generated SignedSource<<f1f989a11e565136a3303e5e804da371>>
 * @relayHash 0d154be96ce3d7195cf6f01ec1921a3a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0d154be96ce3d7195cf6f01ec1921a3a

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ChangeMultipleChoiceDetailsInput = {
  answer?: number | null;
  bodyItemId: string;
  clientMutationId?: string | null;
  options?: ReadonlyArray<HtmlInput> | null;
  prompt?: HtmlInput | null;
  updateAnswer?: boolean | null;
  updateOptions?: boolean | null;
  updatePrompt?: boolean | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type MultipleChoiceChangeMutation$variables = {
  input: ChangeMultipleChoiceDetailsInput;
};
export type MultipleChoiceChangeMutation$data = {
  readonly changeMultipleChoiceDetails: {
    readonly bodyItem: {
      readonly answer: any | null;
      readonly id: string;
      readonly info: any;
    };
  } | null;
};
export type MultipleChoiceChangeMutation = {
  response: MultipleChoiceChangeMutation$data;
  variables: MultipleChoiceChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeMultipleChoiceDetailsPayload",
    "kind": "LinkedField",
    "name": "changeMultipleChoiceDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BodyItem",
        "kind": "LinkedField",
        "name": "bodyItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "info",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answer",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceChangeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0d154be96ce3d7195cf6f01ec1921a3a",
    "metadata": {},
    "name": "MultipleChoiceChangeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6f4a61271318492124104b2899dbe3a9";

export default node;
