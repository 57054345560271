/**
 * @generated SignedSource<<bea95b0a36e4267314a8d1006b70ee5c>>
 * @relayHash d9c09090d10d65fd059b518105cc8e1e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9c09090d10d65fd059b518105cc8e1e

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingQuery$variables = {
  examId: string;
  registrationId: string;
  skipCourse: boolean;
  withRubric: boolean;
};
export type gradingQuery$data = {
  readonly exam: {
    readonly course?: {
      readonly id: string;
      readonly title: string;
    };
    readonly id: string;
    readonly name: string;
  };
  readonly registration: {
    readonly exam: {
      readonly name: string;
    };
    readonly examVersion: {
      readonly answers: any;
      readonly qpPairs: ReadonlyArray<{
        readonly pnum: number;
        readonly qnum: number;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"grading_one" | "grading_showOne">;
  } | null;
};
export type gradingQuery = {
  response: gradingQuery$data;
  variables: gradingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "examId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "registrationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipCourse"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withRubric"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "examId"
    }
  ],
  "concreteType": "Exam",
  "kind": "LinkedField",
  "name": "exam",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "condition": "skipCourse",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Course",
          "kind": "LinkedField",
          "name": "course",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "registrationId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qnum",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pnum",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "QpPair",
  "kind": "LinkedField",
  "name": "qpPairs",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answers",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bnum",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graderHint",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "studentFeedback",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v19 = [
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "RubricPreset",
  "kind": "LinkedField",
  "name": "rubricPreset",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    (v21/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mercy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PresetComment",
      "kind": "LinkedField",
      "name": "presetComments",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v21/*: any*/),
        (v18/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rootRubric",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v14/*: any*/),
    (v20/*: any*/),
    (v22/*: any*/),
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "allSubsections",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        (v14/*: any*/),
        (v20/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Reference",
  "kind": "LinkedField",
  "name": "references",
  "plural": true,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraCredit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingQuery",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "grading_one"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "grading_showOne"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersion",
            "kind": "LinkedField",
            "name": "examVersion",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "gradingQuery",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentAnswers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "GradingCommentConnection",
            "kind": "LinkedField",
            "name": "gradingComments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GradingCommentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradingComment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PresetComment",
                        "kind": "LinkedField",
                        "name": "presetComment",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "gradingComments(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Registration_gradingComments",
            "kind": "LinkedHandle",
            "name": "gradingComments"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GradingCheck",
            "kind": "LinkedField",
            "name": "gradingChecks",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersion",
            "kind": "LinkedField",
            "name": "examVersion",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Question",
                "kind": "LinkedField",
                "name": "dbQuestions",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Part",
                    "kind": "LinkedField",
                    "name": "parts",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BodyItem",
                        "kind": "LinkedField",
                        "name": "bodyItems",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "info",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      (v20/*: any*/),
                      (v26/*: any*/),
                      (v14/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/),
                  (v20/*: any*/),
                  (v27/*: any*/),
                  (v26/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "files",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentGrading",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d9c09090d10d65fd059b518105cc8e1e",
    "metadata": {},
    "name": "gradingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "45a3784b4a1480146fa4a1f5b2bb5662";

export default node;
