/**
 * @generated SignedSource<<b92d4fe6a98168161bfa1114c0844d92>>
 * @relayHash d048668f570743f4c9575ba9d5ef8fb3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d048668f570743f4c9575ba9d5ef8fb3

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderQuestionsInput = {
  clientMutationId?: string | null;
  examVersionId: string;
  fromIndex: number;
  toIndex: number;
};
export type QuestionReorderMutation$variables = {
  input: ReorderQuestionsInput;
};
export type QuestionReorderMutation$data = {
  readonly reorderQuestions: {
    readonly examVersion: {
      readonly dbQuestions: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
      }>;
      readonly id: string;
    };
  } | null;
};
export type QuestionReorderMutation = {
  response: QuestionReorderMutation$data;
  variables: QuestionReorderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderQuestionsPayload",
    "kind": "LinkedField",
    "name": "reorderQuestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamVersion",
        "kind": "LinkedField",
        "name": "examVersion",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "dbQuestions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionReorderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionReorderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "d048668f570743f4c9575ba9d5ef8fb3",
    "metadata": {},
    "name": "QuestionReorderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "221056b8b8cfb06bbb52f33eb2d477e6";

export default node;
