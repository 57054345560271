/**
 * @generated SignedSource<<4e55b7cc8e66a2860ebda65f182b1e59>>
 * @relayHash a2e54a6680acb889e18998e675683688
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a2e54a6680acb889e18998e675683688

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type RubricDirection = "credit" | "deduction";
export type RubricVariant = "all" | "any" | "none" | "one";
export type CreateRubricInput = {
  clientMutationId?: string | null;
  description?: string | null;
  parentSectionId?: string | null;
  points?: number | null;
  type: RubricVariant;
};
export type RubricCreateMutation$variables = {
  input: CreateRubricInput;
};
export type RubricCreateMutation$data = {
  readonly createRubric: {
    readonly parentSection: {
      readonly id: string;
      readonly subsections: ReadonlyArray<{
        readonly id: string;
      }> | null;
    };
    readonly rubric: {
      readonly bodyItem: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly id: string;
        };
      } | null;
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly examVersion: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly id: string;
        } | null;
      };
      readonly id: string;
      readonly order: number | null;
      readonly part: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly id: string;
        };
      } | null;
      readonly points: number | null;
      readonly question: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly id: string;
        };
      } | null;
      readonly rubricPreset: {
        readonly direction: RubricDirection;
        readonly id: string;
        readonly label: string | null;
        readonly mercy: number | null;
        readonly presetComments: ReadonlyArray<{
          readonly graderHint: string;
          readonly id: string;
          readonly label: string | null;
          readonly order: number | null;
          readonly points: number;
          readonly studentFeedback: string | null;
        }>;
      } | null;
      readonly subsections: ReadonlyArray<{
        readonly id: string;
      }> | null;
      readonly type: RubricVariant;
    };
  } | null;
};
export type RubricCreateMutation = {
  response: RubricCreateMutation$data;
  variables: RubricCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Rubric",
    "kind": "LinkedField",
    "name": "rootRubric",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "allSubsections",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRubricPayload",
    "kind": "LinkedField",
    "name": "createRubric",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "parentSection",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RubricPreset",
            "kind": "LinkedField",
            "name": "rubricPreset",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mercy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PresetComment",
                "kind": "LinkedField",
                "name": "presetComments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "graderHint",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studentFeedback",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersion",
            "kind": "LinkedField",
            "name": "examVersion",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Part",
            "kind": "LinkedField",
            "name": "part",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BodyItem",
            "kind": "LinkedField",
            "name": "bodyItem",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricCreateMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricCreateMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "id": "a2e54a6680acb889e18998e675683688",
    "metadata": {},
    "name": "RubricCreateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1e6f0588db2929ff0ee50a891d9a490c";

export default node;
