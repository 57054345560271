/**
 * @generated SignedSource<<7dc96f5b71e7d122fc1557aa67570d5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studentDnd$data = {
  readonly course: {
    readonly sections: ReadonlyArray<{
      readonly id: string;
      readonly students: ReadonlyArray<{
        readonly displayName: string;
        readonly id: string;
        readonly nuid: number | null;
        readonly username: string;
      }>;
      readonly title: string;
    }>;
  };
  readonly id: string;
  readonly registrationsWithoutRooms: ReadonlyArray<{
    readonly user: {
      readonly displayName: string;
      readonly id: string;
      readonly nuid: number | null;
      readonly username: string;
    };
  }>;
  readonly rooms: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly registrations: ReadonlyArray<{
      readonly user: {
        readonly displayName: string;
        readonly id: string;
        readonly nuid: number | null;
        readonly username: string;
      };
    }>;
  }>;
  readonly " $fragmentType": "studentDnd";
};
export type studentDnd$key = {
  readonly " $data"?: studentDnd$data;
  readonly " $fragmentSpreads": FragmentRefs<"studentDnd">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nuid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "studentDnd",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "students",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrationsWithoutRooms",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "rooms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Registration",
          "kind": "LinkedField",
          "name": "registrations",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "d20bd7cb3db3dbbd15577c0964789f0f";

export default node;
