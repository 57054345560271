/**
 * @generated SignedSource<<af546af46ac8849e34b57c47d333611f>>
 * @relayHash 9c290d9c5d451e7ba68ceada04e39093
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c290d9c5d451e7ba68ceada04e39093

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateGradingCommentInput = {
  bnum: number;
  clientMutationId?: string | null;
  message: string;
  pnum: number;
  points: number;
  presetCommentId?: string | null;
  qnum: number;
  registrationId: string;
};
export type createCommentMutation$variables = {
  input: CreateGradingCommentInput;
};
export type createCommentMutation$data = {
  readonly createGradingComment: {
    readonly gradingComment: {
      readonly bnum: number;
      readonly id: string;
      readonly message: string;
      readonly pnum: number;
      readonly points: number;
      readonly presetComment: {
        readonly id: string;
      } | null;
      readonly qnum: number;
    };
    readonly gradingCommentEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  } | null;
};
export type createCommentMutation = {
  response: createCommentMutation$data;
  variables: createCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateGradingCommentPayload",
    "kind": "LinkedField",
    "name": "createGradingComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingComment",
        "kind": "LinkedField",
        "name": "gradingComment",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "points",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PresetComment",
            "kind": "LinkedField",
            "name": "presetComment",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingCommentEdge",
        "kind": "LinkedField",
        "name": "gradingCommentEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GradingComment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCommentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCommentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "9c290d9c5d451e7ba68ceada04e39093",
    "metadata": {},
    "name": "createCommentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bd5cbf9f96e7e6bd03716e153c64ad00";

export default node;
