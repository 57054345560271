/**
 * @generated SignedSource<<591b7f50b05b37dc2891c44caa76186f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingLocks$data = {
  readonly gradingLocks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"gradingLock">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "gradingLocks";
};
export type gradingLocks$key = {
  readonly " $data"?: gradingLocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingLocks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingLocks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GradingLockConnection",
      "kind": "LinkedField",
      "name": "gradingLocks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GradingLockEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GradingLock",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "gradingLock"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};

(node as any).hash = "8922a3e1ddd0ecfc100c0bb06713b0f4";

export default node;
