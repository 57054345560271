/**
 * @generated SignedSource<<7d3c01f3377908ef2504354774a443a4>>
 * @relayHash 323c6472f02af053acf0b8dda8dab986
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 323c6472f02af053acf0b8dda8dab986

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderBodyItemsInput = {
  clientMutationId?: string | null;
  fromIndex: number;
  partId: string;
  toIndex: number;
};
export type BodyItemReorderMutation$variables = {
  input: ReorderBodyItemsInput;
};
export type BodyItemReorderMutation$data = {
  readonly reorderBodyItems: {
    readonly part: {
      readonly bodyItems: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
      }>;
      readonly id: string;
    };
  } | null;
};
export type BodyItemReorderMutation = {
  response: BodyItemReorderMutation$data;
  variables: BodyItemReorderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderBodyItemsPayload",
    "kind": "LinkedField",
    "name": "reorderBodyItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Part",
        "kind": "LinkedField",
        "name": "part",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BodyItem",
            "kind": "LinkedField",
            "name": "bodyItems",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BodyItemReorderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BodyItemReorderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "323c6472f02af053acf0b8dda8dab986",
    "metadata": {},
    "name": "BodyItemReorderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e06d729d85c1e1623e0fcf0616e1892c";

export default node;
