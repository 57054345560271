/**
 * @generated SignedSource<<f93cb35ffc5b5836c782152f688a7c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingVersionAdmin$data = {
  readonly finalizedCount: number;
  readonly id: string;
  readonly name: string;
  readonly startedCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"gradingCompletion" | "gradingLocks">;
  readonly " $fragmentType": "gradingVersionAdmin";
};
export type gradingVersionAdmin$key = {
  readonly " $data"?: gradingVersionAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingVersionAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingVersionAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gradingLocks"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gradingCompletion"
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};

(node as any).hash = "5d24125369063e8febe21991f130695d";

export default node;
