/**
 * @generated SignedSource<<7e22b47744b157d5f7cabbfa9517a68f>>
 * @relayHash 11b81ada3ebd10230606fc7d2fec7955
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 11b81ada3ebd10230606fc7d2fec7955

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderPartsInput = {
  clientMutationId?: string | null;
  fromIndex: number;
  questionId: string;
  toIndex: number;
};
export type PartReorderMutation$variables = {
  input: ReorderPartsInput;
};
export type PartReorderMutation$data = {
  readonly reorderParts: {
    readonly question: {
      readonly id: string;
      readonly parts: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
      }>;
    };
  } | null;
};
export type PartReorderMutation = {
  response: PartReorderMutation$data;
  variables: PartReorderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderPartsPayload",
    "kind": "LinkedField",
    "name": "reorderParts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Question",
        "kind": "LinkedField",
        "name": "question",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Part",
            "kind": "LinkedField",
            "name": "parts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartReorderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartReorderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "11b81ada3ebd10230606fc7d2fec7955",
    "metadata": {},
    "name": "PartReorderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9f849bd7f011eb7d299baf79a8740074";

export default node;
