/**
 * @generated SignedSource<<130abf8fddb81439e292a5a6f90fdda3>>
 * @relayHash 584d9f07117764958dcc360b15196ed6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 584d9f07117764958dcc360b15196ed6

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AskQuestionInput = {
  body: string;
  clientMutationId?: string | null;
  registrationId: string;
};
export type AskQuestionMutation$variables = {
  input: AskQuestionInput;
};
export type AskQuestionMutation$data = {
  readonly askQuestion: {
    readonly studentQuestion: {
      readonly " $fragmentSpreads": FragmentRefs<"AskQuestion_single">;
    };
    readonly studentQuestionEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  } | null;
};
export type AskQuestionMutation = {
  response: AskQuestionMutation$data;
  variables: AskQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "StudentQuestionEdge",
  "kind": "LinkedField",
  "name": "studentQuestionEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudentQuestion",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AskQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AskQuestionPayload",
        "kind": "LinkedField",
        "name": "askQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuestion",
            "kind": "LinkedField",
            "name": "studentQuestion",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AskQuestion_single"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AskQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AskQuestionPayload",
        "kind": "LinkedField",
        "name": "askQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuestion",
            "kind": "LinkedField",
            "name": "studentQuestion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "584d9f07117764958dcc360b15196ed6",
    "metadata": {},
    "name": "AskQuestionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7282a695254c1f45e8310c2b08aa755b";

export default node;
