/**
 * @generated SignedSource<<688cd871e535825be5e5bb5a3b70a059>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type navbar_accordion$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AskQuestion" | "ExamMessages_navbar">;
  readonly " $fragmentType": "navbar_accordion";
};
export type navbar_accordion$key = {
  readonly " $data"?: navbar_accordion$data;
  readonly " $fragmentSpreads": FragmentRefs<"navbar_accordion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "navbar_accordion",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AskQuestion"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamMessages_navbar"
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "7f75c544698e572487332d81a1548ecd";

export default node;
