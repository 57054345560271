/**
 * @generated SignedSource<<58188da90ab68d0f1d8b632e204d6405>>
 * @relayHash 4295c10d094dbef3c195809d04542791
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4295c10d094dbef3c195809d04542791

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SyncCourseToBottlenoseInput = {
  clientMutationId?: string | null;
  courseId: string;
};
export type syncMutation$variables = {
  input: SyncCourseToBottlenoseInput;
};
export type syncMutation$data = {
  readonly syncCourseToBottlenose: {
    readonly course: {
      readonly title: string;
    };
  } | null;
};
export type syncMutation = {
  response: syncMutation$data;
  variables: syncMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncCourseToBottlenosePayload",
        "kind": "LinkedField",
        "name": "syncCourseToBottlenose",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncCourseToBottlenosePayload",
        "kind": "LinkedField",
        "name": "syncCourseToBottlenose",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4295c10d094dbef3c195809d04542791",
    "metadata": {},
    "name": "syncMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "ccee2b119ba10df5b811876af1dbbbfc";

export default node;
