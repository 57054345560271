/**
 * @generated SignedSource<<8c329fa6738863750e2da4558fda6935>>
 * @relayHash 890e8e537c88cc0c59f818e9ecacc56b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 890e8e537c88cc0c59f818e9ecacc56b

import { ConcreteRequest, Query } from 'relay-runtime';
export type submissionsAuditRootQuery$variables = {
  registrationId: string;
};
export type submissionsAuditRootQuery$data = {
  readonly me: {
    readonly id: string;
  };
  readonly registration: {
    readonly exam: {
      readonly id: string;
      readonly name: string;
    };
    readonly published: boolean;
    readonly user: {
      readonly displayName: string;
      readonly id: string;
    };
  } | null;
};
export type submissionsAuditRootQuery = {
  response: submissionsAuditRootQuery$data;
  variables: submissionsAuditRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registrationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "registrationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Exam",
  "kind": "LinkedField",
  "name": "exam",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsAuditRootQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsAuditRootQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "890e8e537c88cc0c59f818e9ecacc56b",
    "metadata": {},
    "name": "submissionsAuditRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cab5848e91857e24d88ef108398faa6f";

export default node;
