/**
 * @generated SignedSource<<e9009079d5b99e1c918eb57f211868d5>>
 * @relayHash 4e7ce75238640b8b053feda9aa1333f2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4e7ce75238640b8b053feda9aa1333f2

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type examsNewQuestionSubscription$variables = {
  examId: string;
};
export type examsNewQuestionSubscription$data = {
  readonly questionWasAsked: {
    readonly studentQuestion: {
      readonly body: string;
      readonly createdAt: string;
      readonly id: string;
      readonly registration: {
        readonly examVersion: {
          readonly id: string;
        };
        readonly id: string;
        readonly room: {
          readonly id: string;
        } | null;
        readonly user: {
          readonly displayName: string;
        };
      };
    };
    readonly studentQuestionsEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type examsNewQuestionSubscription = {
  response: examsNewQuestionSubscription$data;
  variables: examsNewQuestionSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "examId",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Room",
  "kind": "LinkedField",
  "name": "room",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamVersion",
  "kind": "LinkedField",
  "name": "examVersion",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "StudentQuestionEdge",
  "kind": "LinkedField",
  "name": "studentQuestionsEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudentQuestion",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsNewQuestionSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuestionWasAskedPayload",
        "kind": "LinkedField",
        "name": "questionWasAsked",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuestion",
            "kind": "LinkedField",
            "name": "studentQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsNewQuestionSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuestionWasAskedPayload",
        "kind": "LinkedField",
        "name": "questionWasAsked",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuestion",
            "kind": "LinkedField",
            "name": "studentQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4e7ce75238640b8b053feda9aa1333f2",
    "metadata": {},
    "name": "examsNewQuestionSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "40a9d11fc01b5aea642be1d0fd81938c";

export default node;
