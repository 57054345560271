/**
 * @generated SignedSource<<3aee0f25c650b630f9a609c6b7d37a4e>>
 * @relayHash d7f2c4d1aacf7202d92f2c0eabdc062e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d7f2c4d1aacf7202d92f2c0eabdc062e

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PostponeGradingLockInput = {
  clientMutationId?: string | null;
  notes: string;
  pnum: number;
  qnum: number;
  registrationId: string;
};
export type gradingPostponeLockMutation$variables = {
  input: PostponeGradingLockInput;
};
export type gradingPostponeLockMutation$data = {
  readonly postponeGradingLock: {
    readonly gradingLock: {
      readonly id: string;
    };
    readonly released: boolean;
  } | null;
};
export type gradingPostponeLockMutation = {
  response: gradingPostponeLockMutation$data;
  variables: gradingPostponeLockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostponeGradingLockPayload",
    "kind": "LinkedField",
    "name": "postponeGradingLock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "released",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingLock",
        "kind": "LinkedField",
        "name": "gradingLock",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingPostponeLockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingPostponeLockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d7f2c4d1aacf7202d92f2c0eabdc062e",
    "metadata": {},
    "name": "gradingPostponeLockMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "de9762577cdf85731f0da623e0a8fbcb";

export default node;
