/**
 * @generated SignedSource<<789f4cc8dbba3dcdb4f5cfb52b664dd9>>
 * @relayHash 1b3a9e76ba4eabe3ef65817fd289aead
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1b3a9e76ba4eabe3ef65817fd289aead

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangePresetCommentDetailsInput = {
  clientMutationId?: string | null;
  graderHint?: string | null;
  label?: string | null;
  points?: number | null;
  presetCommentId: string;
  studentFeedback?: string | null;
  updateGraderHint?: boolean | null;
  updateLabel?: boolean | null;
  updatePoints?: boolean | null;
  updateStudentFeedback?: boolean | null;
};
export type RubricChangePresetCommentPointsMutation$variables = {
  input: ChangePresetCommentDetailsInput;
};
export type RubricChangePresetCommentPointsMutation$data = {
  readonly changePresetCommentDetails: {
    readonly presetComment: {
      readonly id: string;
      readonly points: number;
    };
  } | null;
};
export type RubricChangePresetCommentPointsMutation = {
  response: RubricChangePresetCommentPointsMutation$data;
  variables: RubricChangePresetCommentPointsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangePresetCommentDetailsPayload",
    "kind": "LinkedField",
    "name": "changePresetCommentDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PresetComment",
        "kind": "LinkedField",
        "name": "presetComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "points",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangePresetCommentPointsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangePresetCommentPointsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "1b3a9e76ba4eabe3ef65817fd289aead",
    "metadata": {},
    "name": "RubricChangePresetCommentPointsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5312b123b6bac6d9bd8584740d1211d4";

export default node;
