/**
 * @generated SignedSource<<123fef748e767cdbad7f63dae4e16e29>>
 * @relayHash b7c836ca4222ee96c576214dd41432bf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b7c836ca4222ee96c576214dd41432bf

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type showCourseQuery$variables = {
  courseId: string;
};
export type showCourseQuery$data = {
  readonly course: {
    readonly exams: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"show_courseExams">;
    }>;
    readonly id: string;
    readonly professors: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
      readonly imageUrl: string | null;
      readonly username: string;
    }>;
    readonly staff: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
      readonly imageUrl: string | null;
      readonly username: string;
    }>;
    readonly students: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
      readonly imageUrl: string | null;
      readonly username: string;
    }>;
    readonly title: string;
  };
};
export type showCourseQuery = {
  response: showCourseQuery$data;
  variables: showCourseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "students",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "staff",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "professors",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "showCourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "show_courseExams"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "showCourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "b7c836ca4222ee96c576214dd41432bf",
    "metadata": {},
    "name": "showCourseQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "84be14a14f965e9bcc68be49bb60281c";

export default node;
