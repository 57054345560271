/**
 * @generated SignedSource<<77cb1fdb8396e95e6acd3f221aa64037>>
 * @relayHash 97bb3e2d42bf54d4c907e7b689ae9d9b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 97bb3e2d42bf54d4c907e7b689ae9d9b

import { ConcreteRequest, Query } from 'relay-runtime';
export type adminBottlenoseJsonQuery$variables = {
  examId: string;
};
export type adminBottlenoseJsonQuery$data = {
  readonly exam: {
    readonly bottlenoseExport: any;
    readonly name: string;
  };
};
export type adminBottlenoseJsonQuery = {
  response: adminBottlenoseJsonQuery$data;
  variables: adminBottlenoseJsonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bottlenoseExport",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminBottlenoseJsonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminBottlenoseJsonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "97bb3e2d42bf54d4c907e7b689ae9d9b",
    "metadata": {},
    "name": "adminBottlenoseJsonQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "79e4a81e68f5b8fffbee3f847ae31375";

export default node;
