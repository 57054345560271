/**
 * @generated SignedSource<<cfb1862fe79285c777e41b1bfd66af51>>
 * @relayHash 4b65475f1882c81c3811f24f90d65806
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4b65475f1882c81c3811f24f90d65806

import { ConcreteRequest, Query } from 'relay-runtime';
export type CourseRole = "NONE" | "PROCTOR" | "PROFESSOR" | "STAFF" | "STUDENT";
export type examsProctorRoleQuery$variables = {
  examId: string;
};
export type examsProctorRoleQuery$data = {
  readonly me: {
    readonly role: CourseRole;
  };
};
export type examsProctorRoleQuery = {
  response: examsProctorRoleQuery$data;
  variables: examsProctorRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "examId",
      "variableName": "examId"
    }
  ],
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsProctorRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsProctorRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4b65475f1882c81c3811f24f90d65806",
    "metadata": {},
    "name": "examsProctorRoleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3ecedf19d8028bb0a5cbcf9a17ebf48b";

export default node;
