/**
 * @generated SignedSource<<8fe566ca6c88de0f8e6b3e6fd60e7de8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type QuestionEditor$data = {
  readonly description: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly extraCredit: boolean;
  readonly id: string;
  readonly index: number;
  readonly name: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly parts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"PartEditor">;
  }>;
  readonly references: ReadonlyArray<{
    readonly id: string;
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly rootRubric: {
    readonly " $fragmentSpreads": FragmentRefs<"RubricSingle">;
  };
  readonly separateSubparts: boolean;
  readonly " $fragmentType": "QuestionEditor";
};
export type QuestionEditor$key = {
  readonly " $data"?: QuestionEditor$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionEditor">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionEditor",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "references",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "separateSubparts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraCredit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "rootRubric",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RubricSingle"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Part",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PartEditor"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};
})();

(node as any).hash = "cb4d1393f151e95e7568d09f949a063e";

export default node;
