/**
 * @generated SignedSource<<7f65f05fc54b42832754c3ba11756ad5>>
 * @relayHash 380ad95a983c2d27addd2c632d420134
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 380ad95a983c2d27addd2c632d420134

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ExamMessagesNewRoomAnnouncementSubscription$variables = {
  roomId: string;
};
export type ExamMessagesNewRoomAnnouncementSubscription$data = {
  readonly roomAnnouncementReceived: {
    readonly roomAnnouncement: {
      readonly body: string;
      readonly createdAt: string;
      readonly id: string;
    };
    readonly roomAnnouncementsEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type ExamMessagesNewRoomAnnouncementSubscription = {
  response: ExamMessagesNewRoomAnnouncementSubscription$data;
  variables: ExamMessagesNewRoomAnnouncementSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "roomId",
        "variableName": "roomId"
      }
    ],
    "concreteType": "RoomAnnouncementReceivedPayload",
    "kind": "LinkedField",
    "name": "roomAnnouncementReceived",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoomAnnouncement",
        "kind": "LinkedField",
        "name": "roomAnnouncement",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RoomAnnouncementEdge",
        "kind": "LinkedField",
        "name": "roomAnnouncementsEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomAnnouncement",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamMessagesNewRoomAnnouncementSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamMessagesNewRoomAnnouncementSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "380ad95a983c2d27addd2c632d420134",
    "metadata": {},
    "name": "ExamMessagesNewRoomAnnouncementSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "194fce577a6f01879675adc0a0a52eb8";

export default node;
