/**
 * @generated SignedSource<<7dfaac86065683750b8eb1febf954af8>>
 * @relayHash 8cfcd43b0506f8d3ae4528bfb674d8a7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8cfcd43b0506f8d3ae4528bfb674d8a7

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeQuery$variables = {};
export type homeQuery$data = {
  readonly activeTerms: ReadonlyArray<{
    readonly id: string;
    readonly myFutureRegistrations: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"home_futureregs">;
    }>;
    readonly myPriorRegistrations: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"home_studentregs">;
    }>;
    readonly myProctorRegistrations: {
      readonly nodes: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"home_proctorregs">;
      } | null> | null;
    };
    readonly myProfessorCourseRegistrations: {
      readonly nodes: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"home_profregs">;
      } | null> | null;
    };
    readonly myRegistrations: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"home_studentregs">;
    }>;
    readonly myStaffRegistrations: {
      readonly nodes: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"home_staffregs">;
      } | null> | null;
    };
    readonly name: string;
  }>;
  readonly me: {
    readonly admin: boolean;
  };
};
export type homeQuery = {
  response: homeQuery$data;
  variables: homeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "home_studentregs"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accommodatedStartTime",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Exam",
  "kind": "LinkedField",
  "name": "exam",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "courseTitle",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Term",
        "kind": "LinkedField",
        "name": "activeTerms",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "myRegistrations",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FutureRegistration",
            "kind": "LinkedField",
            "name": "myFutureRegistrations",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "home_futureregs"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "myPriorRegistrations",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StaffRegistrationConnection",
            "kind": "LinkedField",
            "name": "myStaffRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "home_staffregs"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProctorRegistrationConnection",
            "kind": "LinkedField",
            "name": "myProctorRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProctorRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "home_proctorregs"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfessorCourseRegistrationConnection",
            "kind": "LinkedField",
            "name": "myProfessorCourseRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfessorCourseRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "home_profregs"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "homeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Term",
        "kind": "LinkedField",
        "name": "activeTerms",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "myRegistrations",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FutureRegistration",
            "kind": "LinkedField",
            "name": "myFutureRegistrations",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "examName",
                "storageKey": null
              },
              (v4/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Room",
                "kind": "LinkedField",
                "name": "room",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "myPriorRegistrations",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StaffRegistrationConnection",
            "kind": "LinkedField",
            "name": "myStaffRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Exam",
                        "kind": "LinkedField",
                        "name": "exams",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProctorRegistrationConnection",
            "kind": "LinkedField",
            "name": "myProctorRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProctorRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfessorCourseRegistrationConnection",
            "kind": "LinkedField",
            "name": "myProfessorCourseRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfessorCourseRegistration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8cfcd43b0506f8d3ae4528bfb674d8a7",
    "metadata": {},
    "name": "homeQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e1579d740b9e2c7797b05e2cc27e2753";

export default node;
