/**
 * @generated SignedSource<<e01a93c421804321b4ebb880dea94ff5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type navbar$data = {
  readonly myRegistration: {
    readonly user: {
      readonly displayName: string;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"navbar_accordion">;
  readonly " $fragmentType": "navbar";
};
export type navbar$key = {
  readonly " $data"?: navbar$data;
  readonly " $fragmentSpreads": FragmentRefs<"navbar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "navbar",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "navbar_accordion"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "myRegistration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "b0cc34a69aaeb2c7753e8c355e114e39";

export default node;
