/**
 * @generated SignedSource<<a64a4acdcf06cac17a71794521f4d291>>
 * @relayHash 5d8125baf80fc42cab5b7c49a943f5e3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5d8125baf80fc42cab5b7c49a943f5e3

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ChangeRubricDetailsInput = {
  clientMutationId?: string | null;
  description?: string | null;
  points?: number | null;
  rubricId: string;
  updateDescription?: boolean | null;
  updatePoints?: boolean | null;
};
export type RubricChangeDetailsDescriptionMutation$variables = {
  input: ChangeRubricDetailsInput;
};
export type RubricChangeDetailsDescriptionMutation$data = {
  readonly changeRubricDetails: {
    readonly rubric: {
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly id: string;
    };
  } | null;
};
export type RubricChangeDetailsDescriptionMutation = {
  response: RubricChangeDetailsDescriptionMutation$data;
  variables: RubricChangeDetailsDescriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeRubricDetailsPayload",
    "kind": "LinkedField",
    "name": "changeRubricDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangeDetailsDescriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangeDetailsDescriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5d8125baf80fc42cab5b7c49a943f5e3",
    "metadata": {},
    "name": "RubricChangeDetailsDescriptionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "60e8717d78428300eccde7a6b00b5edc";

export default node;
