/**
 * @generated SignedSource<<aeeba80fb4c5b1a027432efaf2cf710d>>
 * @relayHash abedad610dad001a5bf4879f50546e77
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID abedad610dad001a5bf4879f50546e77

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ExamMessagesSubscription$variables = {
  registrationId: string;
};
export type ExamMessagesSubscription$data = {
  readonly messageReceived: {
    readonly message: {
      readonly body: string;
      readonly createdAt: string;
      readonly id: string;
    };
    readonly messagesEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type ExamMessagesSubscription = {
  response: ExamMessagesSubscription$data;
  variables: ExamMessagesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registrationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "registrationId",
        "variableName": "registrationId"
      }
    ],
    "concreteType": "MessageReceivedPayload",
    "kind": "LinkedField",
    "name": "messageReceived",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageEdge",
        "kind": "LinkedField",
        "name": "messagesEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamMessagesSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamMessagesSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "abedad610dad001a5bf4879f50546e77",
    "metadata": {},
    "name": "ExamMessagesSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "536dd390d3e41685ef53bd7581ca61cb";

export default node;
