/**
 * @generated SignedSource<<0303bf6997d46839a2396090a71786fb>>
 * @relayHash 9f33c902cf0a0239a186c0ba3892c7f3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9f33c902cf0a0239a186c0ba3892c7f3

import { ConcreteRequest, Query } from 'relay-runtime';
export type CourseRole = "NONE" | "PROCTOR" | "PROFESSOR" | "STAFF" | "STUDENT";
export type gradingRoleQuery$variables = {
  examId: string;
};
export type gradingRoleQuery$data = {
  readonly me: {
    readonly displayName: string;
    readonly role: CourseRole;
  };
};
export type gradingRoleQuery = {
  response: gradingRoleQuery$data;
  variables: gradingRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "examId",
      "variableName": "examId"
    }
  ],
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9f33c902cf0a0239a186c0ba3892c7f3",
    "metadata": {},
    "name": "gradingRoleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d73f8e7e8b41a0b3fa097957a668691f";

export default node;
