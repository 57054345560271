/**
 * @generated SignedSource<<5d99c7ce3f301725c533ffb01f2ec48b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingExamAdmin$data = {
  readonly examVersions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"gradingVersionAdmin">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "gradingExamAdmin";
};
export type gradingExamAdmin$key = {
  readonly " $data"?: gradingExamAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingExamAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingExamAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamVersionConnection",
      "kind": "LinkedField",
      "name": "examVersions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "gradingVersionAdmin"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "4040366b5c641bdbbc94344ff73d9b24";

export default node;
