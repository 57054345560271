/**
 * @generated SignedSource<<a97702159ffebb70188fe0c992424330>>
 * @relayHash 86e6e747bc2eafddf3ebf68ce18a1c18
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 86e6e747bc2eafddf3ebf68ce18a1c18

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderPresetCommentsInput = {
  clientMutationId?: string | null;
  fromIndex: number;
  rubricPresetId: string;
  toIndex: number;
};
export type RubricReorderPresetCommentMutation$variables = {
  input: ReorderPresetCommentsInput;
};
export type RubricReorderPresetCommentMutation$data = {
  readonly reorderPresetComments: {
    readonly rubricPreset: {
      readonly id: string;
      readonly presetComments: ReadonlyArray<{
        readonly graderHint: string;
        readonly id: string;
        readonly label: string | null;
        readonly order: number | null;
        readonly points: number;
        readonly studentFeedback: string | null;
      }>;
    };
  } | null;
};
export type RubricReorderPresetCommentMutation = {
  response: RubricReorderPresetCommentMutation$data;
  variables: RubricReorderPresetCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderPresetCommentsPayload",
    "kind": "LinkedField",
    "name": "reorderPresetComments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RubricPreset",
        "kind": "LinkedField",
        "name": "rubricPreset",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PresetComment",
            "kind": "LinkedField",
            "name": "presetComments",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "points",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "graderHint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studentFeedback",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricReorderPresetCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricReorderPresetCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "86e6e747bc2eafddf3ebf68ce18a1c18",
    "metadata": {},
    "name": "RubricReorderPresetCommentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6e0d260fe82c3914949d1bb381935175";

export default node;
