/**
 * @generated SignedSource<<7f076e2fb26e38435acbea291d59f022>>
 * @relayHash cd071a2957a1db819466ceda13959316
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd071a2957a1db819466ceda13959316

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
export type ChangeQuestionDetailsInput = {
  clientMutationId?: string | null;
  description?: HtmlInput | null;
  extraCredit?: boolean | null;
  name?: HtmlInput | null;
  questionId: string;
  references?: ReadonlyArray<ReferenceInput> | null;
  separateSubparts?: boolean | null;
  updateDescription?: boolean | null;
  updateExtraCredit?: boolean | null;
  updateName?: boolean | null;
  updateReferences?: boolean | null;
  updateSeparateSubparts?: boolean | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type ReferenceInput = {
  id?: string | null;
  path: string;
  type: ReferenceType;
};
export type QuestionChangeMutation$variables = {
  input: ChangeQuestionDetailsInput;
};
export type QuestionChangeMutation$data = {
  readonly changeQuestionDetails: {
    readonly question: {
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly extraCredit: boolean;
      readonly id: string;
      readonly name: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly references: ReadonlyArray<{
        readonly id: string;
        readonly path: string;
        readonly type: ReferenceType;
      }>;
      readonly separateSubparts: boolean;
    };
  } | null;
};
export type QuestionChangeMutation = {
  response: QuestionChangeMutation$data;
  variables: QuestionChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeQuestionDetailsPayload",
    "kind": "LinkedField",
    "name": "changeQuestionDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Question",
        "kind": "LinkedField",
        "name": "question",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reference",
            "kind": "LinkedField",
            "name": "references",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extraCredit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "separateSubparts",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionChangeMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionChangeMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "cd071a2957a1db819466ceda13959316",
    "metadata": {},
    "name": "QuestionChangeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "17ea14d018d6d2f92f449f5ffacf88fd";

export default node;
