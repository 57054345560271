/**
 * @generated SignedSource<<5395678078734f95bc30df50ca0a4db8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type home_futureregs$data = ReadonlyArray<{
  readonly accommodatedStartTime: string;
  readonly courseTitle: string;
  readonly examName: string;
  readonly id: string;
  readonly room: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "home_futureregs";
}>;
export type home_futureregs$key = ReadonlyArray<{
  readonly " $data"?: home_futureregs$data;
  readonly " $fragmentSpreads": FragmentRefs<"home_futureregs">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "home_futureregs",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accommodatedStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "room",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FutureRegistration",
  "abstractKey": null
};
})();

(node as any).hash = "871fb6fd3582bf49b0e48633a60ad3a0";

export default node;
