/**
 * @generated SignedSource<<bacddea5cdb5c267a123d294c09cbc59>>
 * @relayHash 4ef16ba774883a7286b9c916453567c2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4ef16ba774883a7286b9c916453567c2

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateExamInput = {
  clientMutationId?: string | null;
  duration: number;
  endTime: string;
  examId: string;
  name: string;
  startTime: string;
};
export type adminUpdateExamMutation$variables = {
  input: UpdateExamInput;
};
export type adminUpdateExamMutation$data = {
  readonly updateExam: {
    readonly exam: {
      readonly duration: number;
      readonly endTime: string;
      readonly name: string;
      readonly startTime: string;
    } | null;
  } | null;
};
export type adminUpdateExamMutation = {
  response: adminUpdateExamMutation$data;
  variables: adminUpdateExamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminUpdateExamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExamPayload",
        "kind": "LinkedField",
        "name": "updateExam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminUpdateExamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExamPayload",
        "kind": "LinkedField",
        "name": "updateExam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4ef16ba774883a7286b9c916453567c2",
    "metadata": {},
    "name": "adminUpdateExamMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b587a7ba7430775eea8e1bed9d9277ea";

export default node;
