/**
 * @generated SignedSource<<0ef928a784ed50e8b55307957844f602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LockdownPolicy = "IGNORE_LOCKDOWN" | "MOCK_LOCKDOWN" | "STUDENT_PIN" | "TOLERATE_WINDOWED";
import { FragmentRefs } from "relay-runtime";
export type admin_preview_version$data = {
  readonly policies: ReadonlyArray<LockdownPolicy>;
  readonly " $fragmentSpreads": FragmentRefs<"showExamViewer">;
  readonly " $fragmentType": "admin_preview_version";
};
export type admin_preview_version$key = {
  readonly " $data"?: admin_preview_version$data;
  readonly " $fragmentSpreads": FragmentRefs<"admin_preview_version">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "admin_preview_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "policies",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "showExamViewer"
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};

(node as any).hash = "16c637b338e3a4e843e73e8575bb7b7d";

export default node;
