/**
 * @generated SignedSource<<3e86f3edfdafa127a834e2e30ddc63f3>>
 * @relayHash dd27daf62b9dc81e5e2344dc89124c84
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dd27daf62b9dc81e5e2344dc89124c84

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type submissionsStaffQuery$variables = {
  registrationId: string;
  withRubric: boolean;
};
export type submissionsStaffQuery$data = {
  readonly registration: {
    readonly currentAnswers: any | null;
    readonly currentGrading: any | null;
    readonly currentScorePercentage: number | null;
    readonly exam: {
      readonly name: string;
    };
    readonly examVersion: {
      readonly " $fragmentSpreads": FragmentRefs<"showExamViewer">;
    };
    readonly published: boolean;
    readonly user: {
      readonly displayName: string;
      readonly nuid: number | null;
    };
  } | null;
};
export type submissionsStaffQuery = {
  response: submissionsStaffQuery$data;
  variables: submissionsStaffQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registrationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withRubric"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "registrationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentAnswers",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentGrading",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentScorePercentage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nuid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v14 = [
  (v10/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "RubricPreset",
  "kind": "LinkedField",
  "name": "rubricPreset",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mercy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PresetComment",
      "kind": "LinkedField",
      "name": "presetComments",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        (v16/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "graderHint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studentFeedback",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = [
  (v9/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v20 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v15/*: any*/),
  (v17/*: any*/),
  (v19/*: any*/)
],
v21 = {
  "condition": "withRubric",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "rootRubric",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v15/*: any*/),
        (v17/*: any*/),
        (v19/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "allSubsections",
          "plural": true,
          "selections": (v20/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v22 = [
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "path",
    "storageKey": null
  },
  (v9/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Reference",
  "kind": "LinkedField",
  "name": "references",
  "plural": true,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraCredit",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rubrics",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsStaffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersion",
            "kind": "LinkedField",
            "name": "examVersion",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "showExamViewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsStaffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamVersion",
            "kind": "LinkedField",
            "name": "examVersion",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Question",
                "kind": "LinkedField",
                "name": "dbQuestions",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Html",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "separateSubparts",
                    "storageKey": null
                  },
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Part",
                    "kind": "LinkedField",
                    "name": "parts",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Html",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      (v24/*: any*/),
                      (v15/*: any*/),
                      (v21/*: any*/),
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BodyItem",
                        "kind": "LinkedField",
                        "name": "bodyItems",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "info",
                            "storageKey": null
                          },
                          (v21/*: any*/),
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Reference",
                "kind": "LinkedField",
                "name": "dbReferences",
                "plural": true,
                "selections": (v22/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Html",
                "kind": "LinkedField",
                "name": "instructions",
                "plural": false,
                "selections": (v14/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "files",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Rubric",
                "kind": "LinkedField",
                "name": "rubrics",
                "plural": true,
                "selections": (v20/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "dd27daf62b9dc81e5e2344dc89124c84",
    "metadata": {},
    "name": "submissionsStaffQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fd8c81de343bf51d1919a7a4cd7597ba";

export default node;
