/**
 * @generated SignedSource<<b55c3084c239ecb0ed3b9073c62e17c9>>
 * @relayHash 470a96c80f0f449778c2c44289621070
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 470a96c80f0f449778c2c44289621070

import { ConcreteRequest, Query } from 'relay-runtime';
export type navbarQuery$variables = {};
export type navbarQuery$data = {
  readonly impersonating: boolean;
  readonly me: {
    readonly displayName: string;
  };
};
export type navbarQuery = {
  response: navbarQuery$data;
  variables: navbarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "impersonating",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navbarQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navbarQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "470a96c80f0f449778c2c44289621070",
    "metadata": {},
    "name": "navbarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ef96b6780180293f2e8338fef726fc3d";

export default node;
