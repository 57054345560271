/**
 * @generated SignedSource<<7cb6b500f19742fd7fbd54919c7c5ab8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type showExamViewerStudent$data = {
  readonly dbReferences: ReadonlyArray<{
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly files: any;
  readonly id: string;
  readonly instructions: {
    readonly type: HtmlTag;
    readonly value: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DisplayQuestions">;
  readonly " $fragmentType": "showExamViewerStudent";
};
export type showExamViewerStudent$key = {
  readonly " $data"?: showExamViewerStudent$data;
  readonly " $fragmentSpreads": FragmentRefs<"showExamViewerStudent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "showExamViewerStudent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplayQuestions"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "dbReferences",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "instructions",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "files",
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};
})();

(node as any).hash = "0364d78eb3fbd9a8b01a468c95a861f2";

export default node;
