/**
 * @generated SignedSource<<9d22b3c395c1278d2270b7baf17b9774>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type ShowQuestion$data = {
  readonly description: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly extraCredit: boolean;
  readonly id: string;
  readonly name: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly parts: ReadonlyArray<{
    readonly extraCredit: boolean;
    readonly id: string;
    readonly name: {
      readonly value: string;
    } | null;
    readonly points: number;
    readonly " $fragmentSpreads": FragmentRefs<"PartShow">;
  }>;
  readonly references: ReadonlyArray<{
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly rootRubric?: {
    readonly " $fragmentSpreads": FragmentRefs<"ShowRubricKey">;
  };
  readonly separateSubparts: boolean;
  readonly " $fragmentType": "ShowQuestion";
};
export type ShowQuestion$key = {
  readonly " $data"?: ShowQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraCredit",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "withRubric"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowQuestion",
  "selections": [
    (v0/*: any*/),
    {
      "condition": "withRubric",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rootRubric",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowRubricKey"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "separateSubparts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "references",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Part",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Html",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PartShow"
        }
      ],
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "type": "Question",
  "abstractKey": null
};
})();

(node as any).hash = "17836dda208d8c395ff981be6a57d542";

export default node;
