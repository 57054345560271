/**
 * @generated SignedSource<<9cafae9416143cecb2d55df39aa83b59>>
 * @relayHash 5d67f83d01b57dd3ddfb606529b90f70
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5d67f83d01b57dd3ddfb606529b90f70

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SyncExamToBottlenoseInput = {
  clientMutationId?: string | null;
  examId: string;
};
export type adminSyncExamToBottlenoseMutation$variables = {
  input: SyncExamToBottlenoseInput;
};
export type adminSyncExamToBottlenoseMutation$data = {
  readonly syncExamToBottlenose: {
    readonly exam: {
      readonly name: string;
    };
  } | null;
};
export type adminSyncExamToBottlenoseMutation = {
  response: adminSyncExamToBottlenoseMutation$data;
  variables: adminSyncExamToBottlenoseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminSyncExamToBottlenoseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncExamToBottlenosePayload",
        "kind": "LinkedField",
        "name": "syncExamToBottlenose",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminSyncExamToBottlenoseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncExamToBottlenosePayload",
        "kind": "LinkedField",
        "name": "syncExamToBottlenose",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5d67f83d01b57dd3ddfb606529b90f70",
    "metadata": {},
    "name": "adminSyncExamToBottlenoseMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3609b9bf2621ec8624063592f18a7bec";

export default node;
