/**
 * @generated SignedSource<<6783d411073de260da3fe15b74036486>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type admin_version$data = {
  readonly anyFinalized: boolean;
  readonly anyStarted: boolean;
  readonly archiveExportUrl: string;
  readonly duration: number | null;
  readonly endTime: string | null;
  readonly fileExportUrl: string;
  readonly id: string;
  readonly name: string;
  readonly startTime: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"admin_preview_version">;
  readonly " $fragmentType": "admin_version";
};
export type admin_version$key = {
  readonly " $data"?: admin_version$data;
  readonly " $fragmentSpreads": FragmentRefs<"admin_version">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "admin_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anyStarted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anyFinalized",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileExportUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archiveExportUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "admin_preview_version"
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};

(node as any).hash = "ff985b133b7f04cdf1dcf622abe388d7";

export default node;
