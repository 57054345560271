/**
 * @generated SignedSource<<271de3ed8a97fc7a4069ed4373349d50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type home_profregs$data = ReadonlyArray<{
  readonly course: {
    readonly id: string;
    readonly title: string;
  };
  readonly " $fragmentType": "home_profregs";
}>;
export type home_profregs$key = ReadonlyArray<{
  readonly " $data"?: home_profregs$data;
  readonly " $fragmentSpreads": FragmentRefs<"home_profregs">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "home_profregs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProfessorCourseRegistration",
  "abstractKey": null
};

(node as any).hash = "f165cc35bb28c1dc20afab1270e727f4";

export default node;
