/**
 * @generated SignedSource<<d542a339ba9386f88fd1f8043ff58d0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LockdownPolicy = "IGNORE_LOCKDOWN" | "MOCK_LOCKDOWN" | "STUDENT_PIN" | "TOLERATE_WINDOWED";
export type PolicyExemption = "IGNORE_LOCKDOWN" | "IGNORE_PIN" | "TOLERATE_WINDOWED";
import { FragmentRefs } from "relay-runtime";
export type ExamTaker$data = {
  readonly id: string;
  readonly myRegistration: {
    readonly examVersion: {
      readonly policies: ReadonlyArray<LockdownPolicy>;
    };
    readonly policyExemptions: ReadonlyArray<PolicyExemption>;
  } | null;
  readonly takeUrl: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExamShowContents" | "PreStart" | "navbar">;
  readonly " $fragmentType": "ExamTaker";
};
export type ExamTaker$key = {
  readonly " $data"?: ExamTaker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTaker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTaker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreStart"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamShowContents"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "navbar"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "takeUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "myRegistration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersion",
          "kind": "LinkedField",
          "name": "examVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "policies",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "policyExemptions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "9dbcea310eadf9f6323641f9cc427b6b";

export default node;
