/**
 * @generated SignedSource<<8ed50e5b83550afcd81ea9f4b611adce>>
 * @relayHash 2d9de0997f632549cdfff33fe301ab95
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2d9de0997f632549cdfff33fe301ab95

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DestroyAccommodationInput = {
  accommodationId: string;
  clientMutationId?: string | null;
};
export type accommodationsDestroyMutation$variables = {
  input: DestroyAccommodationInput;
};
export type accommodationsDestroyMutation$data = {
  readonly destroyAccommodation: {
    readonly deletedId: string;
    readonly registrationEdge: {
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly displayName: string;
        };
      } | null;
    };
  } | null;
};
export type accommodationsDestroyMutation = {
  response: accommodationsDestroyMutation$data;
  variables: accommodationsDestroyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accommodationsDestroyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyAccommodationPayload",
        "kind": "LinkedField",
        "name": "destroyAccommodation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegistrationEdge",
            "kind": "LinkedField",
            "name": "registrationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accommodationsDestroyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyAccommodationPayload",
        "kind": "LinkedField",
        "name": "destroyAccommodation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegistrationEdge",
            "kind": "LinkedField",
            "name": "registrationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2d9de0997f632549cdfff33fe301ab95",
    "metadata": {},
    "name": "accommodationsDestroyMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9736cc844acffec3983c16fc8e0cd6e4";

export default node;
