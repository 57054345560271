/**
 * @generated SignedSource<<958f9ffa9f18ad3b4e5357cbd7767090>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type home_staffregs$data = ReadonlyArray<{
  readonly course: {
    readonly exams: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly id: string;
  };
  readonly id: string;
  readonly " $fragmentType": "home_staffregs";
}>;
export type home_staffregs$key = ReadonlyArray<{
  readonly " $data"?: home_staffregs$data;
  readonly " $fragmentSpreads": FragmentRefs<"home_staffregs">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "home_staffregs",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Exam",
          "kind": "LinkedField",
          "name": "exams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StaffRegistration",
  "abstractKey": null
};
})();

(node as any).hash = "d78548b622e2575dea7cfb2f76cb3f9c";

export default node;
