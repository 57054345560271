/**
 * @generated SignedSource<<3b893e25abd9a41726a2da7afd01d8ab>>
 * @relayHash 537ba431a45bbbbe1a7c71c357e559a1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 537ba431a45bbbbe1a7c71c357e559a1

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type LockdownPolicy = "IGNORE_LOCKDOWN" | "MOCK_LOCKDOWN" | "STUDENT_PIN" | "TOLERATE_WINDOWED";
export type ReferenceType = "dir" | "file";
export type ChangeExamVersionDetailsInput = {
  clientMutationId?: string | null;
  examVersionId: string;
  files?: any | null;
  instructions?: HtmlInput | null;
  name?: string | null;
  policies?: ReadonlyArray<LockdownPolicy> | null;
  references?: ReadonlyArray<ReferenceInput> | null;
  updateFiles?: boolean | null;
  updateInstructions?: boolean | null;
  updateName?: boolean | null;
  updatePolicies?: boolean | null;
  updateReferences?: boolean | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type ReferenceInput = {
  id?: string | null;
  path: string;
  type: ReferenceType;
};
export type editorChangeFilesMutation$variables = {
  input: ChangeExamVersionDetailsInput;
};
export type editorChangeFilesMutation$data = {
  readonly changeExamVersionDetails: {
    readonly examVersion: {
      readonly dbReferences: ReadonlyArray<{
        readonly id: string;
        readonly path: string;
        readonly type: ReferenceType;
      }>;
      readonly files: any;
      readonly id: string;
    };
  } | null;
};
export type editorChangeFilesMutation = {
  response: editorChangeFilesMutation$data;
  variables: editorChangeFilesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeExamVersionDetailsPayload",
    "kind": "LinkedField",
    "name": "changeExamVersionDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamVersion",
        "kind": "LinkedField",
        "name": "examVersion",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Reference",
            "kind": "LinkedField",
            "name": "dbReferences",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "files",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editorChangeFilesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editorChangeFilesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "537ba431a45bbbbe1a7c71c357e559a1",
    "metadata": {},
    "name": "editorChangeFilesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d4002962bad9a9b601878b9273c739fc";

export default node;
