/**
 * @generated SignedSource<<8b65ed9447b1106a7c03feb0553f477d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exams_anomaly$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly priorAnomalyCount: number;
  readonly reason: string;
  readonly registration: {
    readonly final: boolean;
    readonly id: string;
    readonly user: {
      readonly displayName: string;
      readonly id: string;
    };
  };
  readonly " $fragmentType": "exams_anomaly";
};
export type exams_anomaly$key = {
  readonly " $data"?: exams_anomaly$data;
  readonly " $fragmentSpreads": FragmentRefs<"exams_anomaly">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "exams_anomaly",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priorAnomalyCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "final",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Anomaly",
  "abstractKey": null
};
})();

(node as any).hash = "16694780129c15df9602289bd3f16467";

export default node;
