/**
 * @generated SignedSource<<0e35b2fd9e84d0c630384d0ff9cd6aa6>>
 * @relayHash ec20a6e8c4965699696abbfdd9585537
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ec20a6e8c4965699696abbfdd9585537

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateGradingCommentInput = {
  clientMutationId?: string | null;
  gradingCommentId: string;
  message: string;
  points: number;
};
export type gradingUpdateCommentMutation$variables = {
  input: UpdateGradingCommentInput;
};
export type gradingUpdateCommentMutation$data = {
  readonly updateGradingComment: {
    readonly gradingComment: {
      readonly id: string;
      readonly message: string;
      readonly points: number;
      readonly presetComment: {
        readonly graderHint: string;
        readonly id: string;
        readonly points: number;
        readonly studentFeedback: string | null;
      } | null;
    };
  } | null;
};
export type gradingUpdateCommentMutation = {
  response: gradingUpdateCommentMutation$data;
  variables: gradingUpdateCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateGradingCommentPayload",
    "kind": "LinkedField",
    "name": "updateGradingComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingComment",
        "kind": "LinkedField",
        "name": "gradingComment",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PresetComment",
            "kind": "LinkedField",
            "name": "presetComment",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "graderHint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studentFeedback",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingUpdateCommentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingUpdateCommentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "ec20a6e8c4965699696abbfdd9585537",
    "metadata": {},
    "name": "gradingUpdateCommentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bb96740776310fda33cb0e2488d209d7";

export default node;
