/**
 * @generated SignedSource<<38981808da856a70889f91b2ffd6b08d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type PartEditor$data = {
  readonly bodyItems: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BodyItemEditor">;
  }>;
  readonly description: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly extraCredit: boolean;
  readonly id: string;
  readonly index: number;
  readonly name: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly points: number;
  readonly references: ReadonlyArray<{
    readonly id: string;
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly rootRubric: {
    readonly " $fragmentSpreads": FragmentRefs<"RubricSingle">;
  };
  readonly " $fragmentType": "PartEditor";
};
export type PartEditor$key = {
  readonly " $data"?: PartEditor$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartEditor">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartEditor",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "references",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraCredit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "rootRubric",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RubricSingle"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BodyItem",
      "kind": "LinkedField",
      "name": "bodyItems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BodyItemEditor"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Part",
  "abstractKey": null
};
})();

(node as any).hash = "8d776e47afafdd0a4cd7bee71882570a";

export default node;
