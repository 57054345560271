/**
 * @generated SignedSource<<4402b43e5d91adb800f8ff6ff13cd012>>
 * @relayHash 4cf3bbfbe4ee80afd30aaac643c27797
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4cf3bbfbe4ee80afd30aaac643c27797

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DestroyRubricInput = {
  clientMutationId?: string | null;
  rubricId: string;
};
export type RubricDestroyMutation$variables = {
  input: DestroyRubricInput;
};
export type RubricDestroyMutation$data = {
  readonly destroyRubric: {
    readonly parentSection: {
      readonly bodyItem: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
            readonly subsections: ReadonlyArray<{
              readonly id: string;
            }> | null;
          }>;
          readonly id: string;
        };
      } | null;
      readonly examVersion: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
            readonly subsections: ReadonlyArray<{
              readonly id: string;
            }> | null;
          }>;
        } | null;
      };
      readonly part: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
            readonly subsections: ReadonlyArray<{
              readonly id: string;
            }> | null;
          }>;
          readonly id: string;
        };
      } | null;
      readonly question: {
        readonly id: string;
        readonly rootRubric: {
          readonly allSubsections: ReadonlyArray<{
            readonly id: string;
            readonly subsections: ReadonlyArray<{
              readonly id: string;
            }> | null;
          }>;
          readonly id: string;
        };
      } | null;
    };
  } | null;
};
export type RubricDestroyMutation = {
  response: RubricDestroyMutation$data;
  variables: RubricDestroyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "allSubsections",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "subsections",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Rubric",
    "kind": "LinkedField",
    "name": "rootRubric",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Question",
  "kind": "LinkedField",
  "name": "question",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Part",
  "kind": "LinkedField",
  "name": "part",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BodyItem",
  "kind": "LinkedField",
  "name": "bodyItem",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricDestroyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyRubricPayload",
        "kind": "LinkedField",
        "name": "destroyRubric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Rubric",
            "kind": "LinkedField",
            "name": "parentSection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersion",
                "kind": "LinkedField",
                "name": "examVersion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Rubric",
                    "kind": "LinkedField",
                    "name": "rootRubric",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricDestroyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyRubricPayload",
        "kind": "LinkedField",
        "name": "destroyRubric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Rubric",
            "kind": "LinkedField",
            "name": "parentSection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersion",
                "kind": "LinkedField",
                "name": "examVersion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Rubric",
                    "kind": "LinkedField",
                    "name": "rootRubric",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4cf3bbfbe4ee80afd30aaac643c27797",
    "metadata": {},
    "name": "RubricDestroyMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "219631703fcce6ac8f26a129c4b19858";

export default node;
