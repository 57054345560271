/**
 * @generated SignedSource<<a5f764bd311b15ce385205b4bac0db29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BodyItemEditor$data = {
  readonly answer: any | null;
  readonly id: string;
  readonly info: any;
  readonly rootRubric: {
    readonly " $fragmentSpreads": FragmentRefs<"RubricSingle">;
  };
  readonly " $fragmentType": "BodyItemEditor";
};
export type BodyItemEditor$key = {
  readonly " $data"?: BodyItemEditor$data;
  readonly " $fragmentSpreads": FragmentRefs<"BodyItemEditor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BodyItemEditor",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "rootRubric",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RubricSingle"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BodyItem",
  "abstractKey": null
};

(node as any).hash = "59e13c6308b8c89ad01fd7e3dc303e36";

export default node;
