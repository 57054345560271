/**
 * @generated SignedSource<<8ca3ecc9e2bf604ebce60c2859698e27>>
 * @relayHash 193500552b59cb0a981637ab0ac7a9de
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 193500552b59cb0a981637ab0ac7a9de

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RubricDirection = "credit" | "deduction";
export type ChangeRubricPresetDetailsInput = {
  clientMutationId?: string | null;
  direction?: RubricDirection | null;
  label?: string | null;
  rubricPresetId: string;
  updateDirection?: boolean | null;
  updateLabel?: boolean | null;
};
export type RubricChangeRubricPresetDirectionMutation$variables = {
  input: ChangeRubricPresetDetailsInput;
};
export type RubricChangeRubricPresetDirectionMutation$data = {
  readonly changeRubricPresetDetails: {
    readonly rubricPreset: {
      readonly direction: RubricDirection;
      readonly id: string;
    };
  } | null;
};
export type RubricChangeRubricPresetDirectionMutation = {
  response: RubricChangeRubricPresetDirectionMutation$data;
  variables: RubricChangeRubricPresetDirectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeRubricPresetDetailsPayload",
    "kind": "LinkedField",
    "name": "changeRubricPresetDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RubricPreset",
        "kind": "LinkedField",
        "name": "rubricPreset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "direction",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangeRubricPresetDirectionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangeRubricPresetDirectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "193500552b59cb0a981637ab0ac7a9de",
    "metadata": {},
    "name": "RubricChangeRubricPresetDirectionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fe18c43aca5ab831bb4b25f0e39d09d8";

export default node;
