/**
 * @generated SignedSource<<b3a43be7b9f32adfee1f9b4e05e2985e>>
 * @relayHash a84f210af64e8edc494d336c98a5be80
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a84f210af64e8edc494d336c98a5be80

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type examsNewVersionAnnouncementSubscription$variables = {
  examId: string;
};
export type examsNewVersionAnnouncementSubscription$data = {
  readonly versionAnnouncementWasSent: {
    readonly versionAnnouncement: {
      readonly body: string;
      readonly createdAt: string;
      readonly examVersion: {
        readonly name: string;
      };
      readonly id: string;
    };
    readonly versionAnnouncementsEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type examsNewVersionAnnouncementSubscription = {
  response: examsNewVersionAnnouncementSubscription$data;
  variables: examsNewVersionAnnouncementSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "examId",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "VersionAnnouncementEdge",
  "kind": "LinkedField",
  "name": "versionAnnouncementsEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VersionAnnouncement",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsNewVersionAnnouncementSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VersionAnnouncementWasSentPayload",
        "kind": "LinkedField",
        "name": "versionAnnouncementWasSent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VersionAnnouncement",
            "kind": "LinkedField",
            "name": "versionAnnouncement",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersion",
                "kind": "LinkedField",
                "name": "examVersion",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsNewVersionAnnouncementSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VersionAnnouncementWasSentPayload",
        "kind": "LinkedField",
        "name": "versionAnnouncementWasSent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VersionAnnouncement",
            "kind": "LinkedField",
            "name": "versionAnnouncement",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersion",
                "kind": "LinkedField",
                "name": "examVersion",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a84f210af64e8edc494d336c98a5be80",
    "metadata": {},
    "name": "examsNewVersionAnnouncementSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "9e2ff0618f7f75174036448b92bd22f4";

export default node;
