/**
 * @generated SignedSource<<5216785f3d553fc7b0365a318fd6a960>>
 * @relayHash 62a5d5c7fedbd57cd340012cb6b79899
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 62a5d5c7fedbd57cd340012cb6b79899

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HtmlTag = "HTML";
export type CreateMatchingInput = {
  answer?: ReadonlyArray<number> | null;
  clientMutationId?: string | null;
  matchValues: ReadonlyArray<HtmlInput>;
  matchValuesLabel?: HtmlInput | null;
  partId: string;
  prompt?: HtmlInput | null;
  prompts: ReadonlyArray<HtmlInput>;
  promptsLabel?: HtmlInput | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type MatchingCreateMutation$variables = {
  input: CreateMatchingInput;
};
export type MatchingCreateMutation$data = {
  readonly createMatching: {
    readonly part: {
      readonly bodyItems: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"BodyItemEditor">;
      }>;
      readonly id: string;
    };
  } | null;
};
export type MatchingCreateMutation = {
  response: MatchingCreateMutation$data;
  variables: MatchingCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RubricPreset",
  "kind": "LinkedField",
  "name": "rubricPreset",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mercy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PresetComment",
      "kind": "LinkedField",
      "name": "presetComments",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v7/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "graderHint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studentFeedback",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchingCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMatchingPayload",
        "kind": "LinkedField",
        "name": "createMatching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Part",
            "kind": "LinkedField",
            "name": "part",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BodyItem",
                "kind": "LinkedField",
                "name": "bodyItems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BodyItemEditor"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchingCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMatchingPayload",
        "kind": "LinkedField",
        "name": "createMatching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Part",
            "kind": "LinkedField",
            "name": "part",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BodyItem",
                "kind": "LinkedField",
                "name": "bodyItems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "info",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Rubric",
                    "kind": "LinkedField",
                    "name": "rootRubric",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Rubric",
                        "kind": "LinkedField",
                        "name": "allSubsections",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "62a5d5c7fedbd57cd340012cb6b79899",
    "metadata": {},
    "name": "MatchingCreateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "224600792b76184915f0210afb0ea5b0";

export default node;
