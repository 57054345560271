/**
 * @generated SignedSource<<344ceb42b0054f9907bb83e8d4442422>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingCompletion$data = {
  readonly completionSummary: any;
  readonly " $fragmentType": "gradingCompletion";
};
export type gradingCompletion$key = {
  readonly " $data"?: gradingCompletion$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingCompletion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingCompletion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completionSummary",
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};

(node as any).hash = "da6d012ba4df823a488e0196a922d3de";

export default node;
