/**
 * @generated SignedSource<<7c5c44a676890eefd207a728628e09fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignStaff$data = {
  readonly course: {
    readonly sections: ReadonlyArray<{
      readonly id: string;
      readonly staff: ReadonlyArray<{
        readonly displayName: string;
        readonly id: string;
        readonly nuid: number | null;
        readonly username: string;
      }>;
      readonly title: string;
    }>;
  };
  readonly id: string;
  readonly proctorRegistrationsWithoutRooms: ReadonlyArray<{
    readonly user: {
      readonly displayName: string;
      readonly id: string;
      readonly nuid: number | null;
      readonly username: string;
    };
  }>;
  readonly rooms: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly proctorRegistrations: ReadonlyArray<{
      readonly id: string;
      readonly user: {
        readonly displayName: string;
        readonly id: string;
        readonly nuid: number | null;
        readonly username: string;
      };
    }>;
  }>;
  readonly unassignedStaff: ReadonlyArray<{
    readonly displayName: string;
    readonly id: string;
    readonly nuid: number | null;
    readonly username: string;
  }>;
  readonly " $fragmentType": "assignStaff";
};
export type assignStaff$key = {
  readonly " $data"?: assignStaff$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignStaff">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nuid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "assignStaff",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "unassignedStaff",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "rooms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProctorRegistration",
          "kind": "LinkedField",
          "name": "proctorRegistrations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "staff",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProctorRegistration",
      "kind": "LinkedField",
      "name": "proctorRegistrationsWithoutRooms",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "31eb57485c0e40a3e80312340ef02d15";

export default node;
