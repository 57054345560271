/**
 * @generated SignedSource<<0b1fd2463642e19f9877111d31b05386>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AskQuestion_single$data = {
  readonly body: string;
  readonly createdAt: string;
  readonly " $fragmentType": "AskQuestion_single";
};
export type AskQuestion_single$key = {
  readonly " $data"?: AskQuestion_single$data;
  readonly " $fragmentSpreads": FragmentRefs<"AskQuestion_single">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AskQuestion_single",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "type": "StudentQuestion",
  "abstractKey": null
};

(node as any).hash = "d54d33b11b5f25e3160ecb6aca20c3ea";

export default node;
