/**
 * @generated SignedSource<<89286b34b47931f30efd880736ee8e5d>>
 * @relayHash d6b8586c611fc933e452e18ea8439693
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d6b8586c611fc933e452e18ea8439693

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeRubricDetailsInput = {
  clientMutationId?: string | null;
  description?: string | null;
  points?: number | null;
  rubricId: string;
  updateDescription?: boolean | null;
  updatePoints?: boolean | null;
};
export type RubricChangeDetailsPointsMutation$variables = {
  input: ChangeRubricDetailsInput;
};
export type RubricChangeDetailsPointsMutation$data = {
  readonly changeRubricDetails: {
    readonly rubric: {
      readonly id: string;
      readonly points: number | null;
    };
  } | null;
};
export type RubricChangeDetailsPointsMutation = {
  response: RubricChangeDetailsPointsMutation$data;
  variables: RubricChangeDetailsPointsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeRubricDetailsPayload",
    "kind": "LinkedField",
    "name": "changeRubricDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "points",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangeDetailsPointsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangeDetailsPointsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d6b8586c611fc933e452e18ea8439693",
    "metadata": {},
    "name": "RubricChangeDetailsPointsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b51036932eefc2768ee3af1323f31f88";

export default node;
