/**
 * @generated SignedSource<<8ba458801b5a0ba061558cd2519642f9>>
 * @relayHash 2d1df7a1540b3d62b84ce822834b9b16
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2d1df7a1540b3d62b84ce822834b9b16

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ChangeMatchingDetailsInput = {
  answer?: ReadonlyArray<number> | null;
  bodyItemId: string;
  clientMutationId?: string | null;
  matchValues?: ReadonlyArray<HtmlInput> | null;
  matchValuesLabel?: HtmlInput | null;
  prompt?: HtmlInput | null;
  prompts?: ReadonlyArray<HtmlInput> | null;
  promptsLabel?: HtmlInput | null;
  updateAnswer?: boolean | null;
  updateMatchValues?: boolean | null;
  updateMatchValuesLabel?: boolean | null;
  updatePrompt?: boolean | null;
  updatePrompts?: boolean | null;
  updatePromptsLabel?: boolean | null;
};
export type HtmlInput = {
  type: HtmlTag;
  value: string;
};
export type MatchingChangeMutation$variables = {
  input: ChangeMatchingDetailsInput;
};
export type MatchingChangeMutation$data = {
  readonly changeMatchingDetails: {
    readonly bodyItem: {
      readonly answer: any | null;
      readonly id: string;
      readonly info: any;
    };
  } | null;
};
export type MatchingChangeMutation = {
  response: MatchingChangeMutation$data;
  variables: MatchingChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeMatchingDetailsPayload",
    "kind": "LinkedField",
    "name": "changeMatchingDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BodyItem",
        "kind": "LinkedField",
        "name": "bodyItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "info",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answer",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchingChangeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchingChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2d1df7a1540b3d62b84ce822834b9b16",
    "metadata": {},
    "name": "MatchingChangeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d6e43de55c088ce8c6f9185eb2fa3f3f";

export default node;
