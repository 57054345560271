/**
 * @generated SignedSource<<0940f12ba3fd16ab9572185a19ccd16e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingRubric$data = {
  readonly dbQuestions: ReadonlyArray<{
    readonly id: string;
    readonly parts: ReadonlyArray<{
      readonly bodyItems: ReadonlyArray<{
        readonly id: string;
        readonly rootRubric: {
          readonly " $fragmentSpreads": FragmentRefs<"UseRubricsKey">;
        };
      }>;
      readonly id: string;
      readonly rootRubric: {
        readonly " $fragmentSpreads": FragmentRefs<"UseRubricsKey">;
      };
    }>;
    readonly rootRubric: {
      readonly " $fragmentSpreads": FragmentRefs<"UseRubricsKey">;
    };
  }>;
  readonly id: string;
  readonly rootRubric: {
    readonly " $fragmentSpreads": FragmentRefs<"UseRubricsKey">;
  } | null;
  readonly " $fragmentType": "gradingRubric";
};
export type gradingRubric$key = {
  readonly " $data"?: gradingRubric$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingRubric">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rootRubric",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UseRubricsKey"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingRubric",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "dbQuestions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Part",
          "kind": "LinkedField",
          "name": "parts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BodyItem",
              "kind": "LinkedField",
              "name": "bodyItems",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};
})();

(node as any).hash = "f7a446f35b1721416660d7ff377f6388";

export default node;
