/**
 * @generated SignedSource<<f242b0cedada7fbfecafd56b2f8f8473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
import { FragmentRefs } from "relay-runtime";
export type RubricPresetHistograms$data = {
  readonly dbQuestions: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly name: {
      readonly type: HtmlTag;
      readonly value: string;
    } | null;
    readonly parts: ReadonlyArray<{
      readonly bodyItems: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
        readonly rootRubric: {
          readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistogramsUseRubrics">;
        };
      }>;
      readonly id: string;
      readonly index: number;
      readonly name: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly rootRubric: {
        readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistogramsUseRubrics">;
      };
    }>;
    readonly rootRubric: {
      readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistogramsUseRubrics">;
    };
  }>;
  readonly id: string;
  readonly registrations: ReadonlyArray<{
    readonly allGradingComments: ReadonlyArray<{
      readonly bnum: number;
      readonly id: string;
      readonly message: string;
      readonly pnum: number;
      readonly points: number;
      readonly presetComment: {
        readonly graderHint: string;
        readonly id: string;
        readonly order: number | null;
        readonly points: number;
        readonly rubricPreset: {
          readonly id: string;
        };
        readonly studentFeedback: string | null;
      } | null;
      readonly qnum: number;
    }>;
    readonly id: string;
    readonly user: {
      readonly displayName: string;
      readonly id: string;
    };
  }>;
  readonly rootRubric: {
    readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistogramsUseRubrics">;
  } | null;
  readonly " $fragmentType": "RubricPresetHistograms";
};
export type RubricPresetHistograms$key = {
  readonly " $data"?: RubricPresetHistograms$data;
  readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistograms">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rootRubric",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RubricPresetHistogramsUseRubrics"
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RubricPresetHistograms",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "dbQuestions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Part",
          "kind": "LinkedField",
          "name": "parts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BodyItem",
              "kind": "LinkedField",
              "name": "bodyItems",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GradingComment",
          "kind": "LinkedField",
          "name": "allGradingComments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qnum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pnum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bnum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PresetComment",
              "kind": "LinkedField",
              "name": "presetComment",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "order",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "graderHint",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "studentFeedback",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RubricPreset",
                  "kind": "LinkedField",
                  "name": "rubricPreset",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamVersion",
  "abstractKey": null
};
})();

(node as any).hash = "3b5bb9f9be0810cbc557f23419ac6a25";

export default node;
