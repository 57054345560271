/**
 * @generated SignedSource<<05e55950adb44602d33a6548838102a7>>
 * @relayHash 58bc14d45be6e1685a760f59e3c35d56
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 58bc14d45be6e1685a760f59e3c35d56

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderRubricsInput = {
  clientMutationId?: string | null;
  fromIndex: number;
  parentSectionId: string;
  toIndex: number;
};
export type RubricReorderMutation$variables = {
  input: ReorderRubricsInput;
};
export type RubricReorderMutation$data = {
  readonly reorderRubrics: {
    readonly parentSection: {
      readonly id: string;
      readonly subsections: ReadonlyArray<{
        readonly id: string;
        readonly order: number | null;
      }> | null;
    };
  } | null;
};
export type RubricReorderMutation = {
  response: RubricReorderMutation$data;
  variables: RubricReorderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderRubricsPayload",
    "kind": "LinkedField",
    "name": "reorderRubrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "parentSection",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Rubric",
            "kind": "LinkedField",
            "name": "subsections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricReorderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricReorderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "58bc14d45be6e1685a760f59e3c35d56",
    "metadata": {},
    "name": "RubricReorderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "37fdb2a41fbc1e1d2281feb03f136aa5";

export default node;
