/**
 * @generated SignedSource<<d84acb33a08a40d02478e0ddafa6c472>>
 * @relayHash 35873dfa738db6b5f50eb612efbc3df9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 35873dfa738db6b5f50eb612efbc3df9

import { ConcreteRequest, Query } from 'relay-runtime';
export type homeAdminQuery$variables = {};
export type homeAdminQuery$data = {
  readonly users: ReadonlyArray<{
    readonly displayName: string;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly username: string;
  }>;
};
export type homeAdminQuery = {
  response: homeAdminQuery$data;
  variables: homeAdminQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeAdminQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "homeAdminQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "35873dfa738db6b5f50eb612efbc3df9",
    "metadata": {},
    "name": "homeAdminQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1682cb55bfa6658ff649f483f7efee13";

export default node;
