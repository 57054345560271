/**
 * @generated SignedSource<<e0fceca9ac2265eadcd5a8484c014990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accommodations_all$data = {
  readonly accommodations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly registration: {
          readonly user: {
            readonly displayName: string;
          };
        };
        readonly " $fragmentSpreads": FragmentRefs<"accommodations_accommodation">;
      } | null;
    } | null> | null;
  };
  readonly course: {
    readonly exams: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"accommodations_regsWithout">;
  readonly " $fragmentType": "accommodations_all";
};
export type accommodations_all$key = {
  readonly " $data"?: accommodations_all$data;
  readonly " $fragmentSpreads": FragmentRefs<"accommodations_all">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "accommodations"
        ]
      }
    ]
  },
  "name": "accommodations_all",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Exam",
          "kind": "LinkedField",
          "name": "exams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "accommodations_regsWithout"
    },
    {
      "alias": "accommodations",
      "args": null,
      "concreteType": "AccommodationConnection",
      "kind": "LinkedField",
      "name": "__Exam_accommodations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccommodationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Accommodation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Registration",
                  "kind": "LinkedField",
                  "name": "registration",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "accommodations_accommodation"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "84a7d6ffdebd264db8eb8d3b791996d8";

export default node;
