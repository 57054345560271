/**
 * @generated SignedSource<<b989b0ba95a72c93c49858fd22065327>>
 * @relayHash 650cffeeaee7c63804d043ffa420316b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 650cffeeaee7c63804d043ffa420316b

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtmlTag = "HTML";
export type RubricDirection = "credit" | "deduction";
export type RubricVariant = "all" | "any" | "none" | "one";
export type ChangeRubricTypeInput = {
  clientMutationId?: string | null;
  rubricId: string;
  type: RubricVariant;
};
export type RubricChangeTypeMutation$variables = {
  input: ChangeRubricTypeInput;
};
export type RubricChangeTypeMutation$data = {
  readonly changeRubricType: {
    readonly rubric: {
      readonly bnum: number | null;
      readonly description: {
        readonly type: HtmlTag;
        readonly value: string;
      } | null;
      readonly id: string;
      readonly order: number | null;
      readonly pnum: number | null;
      readonly points: number | null;
      readonly qnum: number | null;
      readonly rubricPreset: {
        readonly direction: RubricDirection;
        readonly id: string;
        readonly label: string | null;
        readonly mercy: number | null;
        readonly presetComments: ReadonlyArray<{
          readonly graderHint: string;
          readonly id: string;
          readonly label: string | null;
          readonly order: number | null;
          readonly points: number;
          readonly studentFeedback: string | null;
        }>;
      } | null;
      readonly subsections: ReadonlyArray<{
        readonly id: string;
      }> | null;
      readonly type: RubricVariant;
    };
  } | null;
};
export type RubricChangeTypeMutation = {
  response: RubricChangeTypeMutation$data;
  variables: RubricChangeTypeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeRubricTypePayload",
    "kind": "LinkedField",
    "name": "changeRubricType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bnum",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Html",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RubricPreset",
            "kind": "LinkedField",
            "name": "rubricPreset",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mercy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PresetComment",
                "kind": "LinkedField",
                "name": "presetComments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "graderHint",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studentFeedback",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rubric",
            "kind": "LinkedField",
            "name": "subsections",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricChangeTypeMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricChangeTypeMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "650cffeeaee7c63804d043ffa420316b",
    "metadata": {},
    "name": "RubricChangeTypeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7812c32f0f898a9c0c8a9b5733edbeec";

export default node;
