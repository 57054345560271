/**
 * @generated SignedSource<<148cb4db17f616cfe2009d5a3e975214>>
 * @relayHash cb11f3b948a02040a86fa59c11b64353
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cb11f3b948a02040a86fa59c11b64353

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GradeNextInput = {
  allowChangeProblems?: boolean | null;
  clientMutationId?: string | null;
  examId: string;
  examVersionId?: string | null;
  pnum?: number | null;
  qnum?: number | null;
};
export type gradingNextMutation$variables = {
  input: GradeNextInput;
};
export type gradingNextMutation$data = {
  readonly gradeNext: {
    readonly pnum: number;
    readonly qnum: number;
    readonly registrationId: string;
  } | null;
};
export type gradingNextMutation = {
  response: gradingNextMutation$data;
  variables: gradingNextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GradeNextPayload",
    "kind": "LinkedField",
    "name": "gradeNext",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qnum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pnum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingNextMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingNextMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cb11f3b948a02040a86fa59c11b64353",
    "metadata": {},
    "name": "gradingNextMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a5fe3b937a34812abcb922ad30789cf9";

export default node;
