/**
 * @generated SignedSource<<04062c8bed5e87fd4d193be590b91336>>
 * @relayHash 38aa0b0408554f8111b6664be173f4fa
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 38aa0b0408554f8111b6664be173f4fa

import { ConcreteRequest, Query } from 'relay-runtime';
export type submissionsRootQuery$variables = {
  registrationId: string;
  skipCourse: boolean;
};
export type submissionsRootQuery$data = {
  readonly registration: {
    readonly exam: {
      readonly course?: {
        readonly id: string;
        readonly title: string;
      };
      readonly id: string;
      readonly name: string;
    };
    readonly published: boolean;
    readonly user: {
      readonly displayName: string;
      readonly id: string;
    };
  } | null;
};
export type submissionsRootQuery = {
  response: submissionsRootQuery$data;
  variables: submissionsRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registrationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipCourse"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "registrationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Exam",
  "kind": "LinkedField",
  "name": "exam",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "condition": "skipCourse",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Course",
          "kind": "LinkedField",
          "name": "course",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "38aa0b0408554f8111b6664be173f4fa",
    "metadata": {},
    "name": "submissionsRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "92f0ad60a098bf2d5af5a5c31aff6f96";

export default node;
