/**
 * @generated SignedSource<<0a6fb4bf530449b57ac85c6958612ac0>>
 * @relayHash 10d46e1422313454f34318648640067c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 10d46e1422313454f34318648640067c

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChecklistItemStatus = "COMPLETE" | "NA" | "NOT_STARTED" | "WARNING";
export type DestroyExamVersionInput = {
  clientMutationId?: string | null;
  examVersionId: string;
};
export type adminDestroyVersionMutation$variables = {
  input: DestroyExamVersionInput;
};
export type adminDestroyVersionMutation$data = {
  readonly destroyExamVersion: {
    readonly deletedId: string;
    readonly exam: {
      readonly checklist: {
        readonly seating: {
          readonly reason: string;
          readonly status: ChecklistItemStatus;
        };
        readonly staff: {
          readonly reason: string;
          readonly status: ChecklistItemStatus;
        };
        readonly timing: {
          readonly reason: string;
          readonly status: ChecklistItemStatus;
        };
        readonly versions: {
          readonly reason: string;
          readonly status: ChecklistItemStatus;
        };
      };
    };
  } | null;
};
export type adminDestroyVersionMutation = {
  response: adminDestroyVersionMutation$data;
  variables: adminDestroyVersionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reason",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamChecklist",
  "kind": "LinkedField",
  "name": "checklist",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamChecklistSection",
      "kind": "LinkedField",
      "name": "timing",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamChecklistSection",
      "kind": "LinkedField",
      "name": "staff",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamChecklistSection",
      "kind": "LinkedField",
      "name": "seating",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamChecklistSection",
      "kind": "LinkedField",
      "name": "versions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminDestroyVersionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyExamVersionPayload",
        "kind": "LinkedField",
        "name": "destroyExamVersion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminDestroyVersionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyExamVersionPayload",
        "kind": "LinkedField",
        "name": "destroyExamVersion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "10d46e1422313454f34318648640067c",
    "metadata": {},
    "name": "adminDestroyVersionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4a752ea6df17049a84ee482dd6981d89";

export default node;
