/**
 * @generated SignedSource<<fe3323ff06a9a84b3f19945e7e9672a2>>
 * @relayHash db71e3f6cebe6d9b5154a47ba8bb7872
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID db71e3f6cebe6d9b5154a47ba8bb7872

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RubricDirection = "credit" | "deduction";
export type CreateRubricPresetInput = {
  clientMutationId?: string | null;
  direction: string;
  label?: string | null;
  mercy?: number | null;
  rubricId: string;
};
export type RubricCreateRubricPresetMutation$variables = {
  input: CreateRubricPresetInput;
};
export type RubricCreateRubricPresetMutation$data = {
  readonly createRubricPreset: {
    readonly rubric: {
      readonly id: string;
      readonly rubricPreset: {
        readonly direction: RubricDirection;
        readonly id: string;
        readonly label: string | null;
        readonly mercy: number | null;
        readonly presetComments: ReadonlyArray<{
          readonly graderHint: string;
          readonly id: string;
          readonly label: string | null;
          readonly order: number | null;
          readonly points: number;
          readonly studentFeedback: string | null;
        }>;
      } | null;
    };
  } | null;
};
export type RubricCreateRubricPresetMutation = {
  response: RubricCreateRubricPresetMutation$data;
  variables: RubricCreateRubricPresetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRubricPresetPayload",
    "kind": "LinkedField",
    "name": "createRubricPreset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RubricPreset",
            "kind": "LinkedField",
            "name": "rubricPreset",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mercy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PresetComment",
                "kind": "LinkedField",
                "name": "presetComments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "points",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "graderHint",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studentFeedback",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricCreateRubricPresetMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricCreateRubricPresetMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "db71e3f6cebe6d9b5154a47ba8bb7872",
    "metadata": {},
    "name": "RubricCreateRubricPresetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c44a602d5721465c86b5bdd41bf77600";

export default node;
