/**
 * @generated SignedSource<<dbb64c419ee9b0f4ef1c6a57f1a4f77d>>
 * @relayHash a675c0c1dac494f881bd4ec72c4c96a7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a675c0c1dac494f881bd4ec72c4c96a7

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type navbarStopImpersonatingMutation$variables = {};
export type navbarStopImpersonatingMutation$data = {
  readonly stopImpersonating: {
    readonly success: boolean;
  } | null;
};
export type navbarStopImpersonatingMutation = {
  response: navbarStopImpersonatingMutation$data;
  variables: navbarStopImpersonatingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "StopImpersonatingPayload",
    "kind": "LinkedField",
    "name": "stopImpersonating",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": "stopImpersonating(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navbarStopImpersonatingMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navbarStopImpersonatingMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "a675c0c1dac494f881bd4ec72c4c96a7",
    "metadata": {},
    "name": "navbarStopImpersonatingMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "29eafd29f416d5af65138d6bd7c35958";

export default node;
