/**
 * @generated SignedSource<<b8ecddb2a0abca35bb6c2a4ef13834b7>>
 * @relayHash 54dfa044ee278beb2dee25b830addd50
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 54dfa044ee278beb2dee25b830addd50

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePresetCommentInput = {
  clientMutationId?: string | null;
  graderHint: string;
  label?: string | null;
  points: number;
  rubricPresetId: string;
  studentFeedback?: string | null;
};
export type RubricCreatePresetCommentMutation$variables = {
  input: CreatePresetCommentInput;
};
export type RubricCreatePresetCommentMutation$data = {
  readonly createPresetComment: {
    readonly rubricPreset: {
      readonly id: string;
      readonly presetComments: ReadonlyArray<{
        readonly graderHint: string;
        readonly id: string;
        readonly label: string | null;
        readonly order: number | null;
        readonly points: number;
        readonly studentFeedback: string | null;
      }>;
    };
  } | null;
};
export type RubricCreatePresetCommentMutation = {
  response: RubricCreatePresetCommentMutation$data;
  variables: RubricCreatePresetCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePresetCommentPayload",
    "kind": "LinkedField",
    "name": "createPresetComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RubricPreset",
        "kind": "LinkedField",
        "name": "rubricPreset",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PresetComment",
            "kind": "LinkedField",
            "name": "presetComments",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "points",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "graderHint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studentFeedback",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricCreatePresetCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricCreatePresetCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "54dfa044ee278beb2dee25b830addd50",
    "metadata": {},
    "name": "RubricCreatePresetCommentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9a3ad97a6459c581cceff7585e1f2eae";

export default node;
