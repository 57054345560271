/**
 * @generated SignedSource<<4b868c8be69685708b7c7041b4bc787e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type RubricDirection = "credit" | "deduction";
export type RubricVariant = "all" | "any" | "none" | "one";
import { FragmentRefs } from "relay-runtime";
export type RubricSingle$data = {
  readonly allSubsections: ReadonlyArray<{
    readonly description: {
      readonly type: HtmlTag;
      readonly value: string;
    } | null;
    readonly id: string;
    readonly order: number | null;
    readonly points: number | null;
    readonly rubricPreset: {
      readonly direction: RubricDirection;
      readonly id: string;
      readonly label: string | null;
      readonly mercy: number | null;
      readonly presetComments: ReadonlyArray<{
        readonly graderHint: string;
        readonly id: string;
        readonly label: string | null;
        readonly order: number | null;
        readonly points: number;
        readonly studentFeedback: string | null;
      }>;
    } | null;
    readonly subsections: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly type: RubricVariant;
  }>;
  readonly description: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly id: string;
  readonly order: number | null;
  readonly points: number | null;
  readonly rubricPreset: {
    readonly direction: RubricDirection;
    readonly id: string;
    readonly label: string | null;
    readonly mercy: number | null;
    readonly presetComments: ReadonlyArray<{
      readonly graderHint: string;
      readonly id: string;
      readonly label: string | null;
      readonly order: number | null;
      readonly points: number;
      readonly studentFeedback: string | null;
    }>;
  } | null;
  readonly subsections: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly type: RubricVariant;
  readonly " $fragmentType": "RubricSingle";
};
export type RubricSingle$key = {
  readonly " $data"?: RubricSingle$data;
  readonly " $fragmentSpreads": FragmentRefs<"RubricSingle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RubricPreset",
  "kind": "LinkedField",
  "name": "rubricPreset",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mercy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PresetComment",
      "kind": "LinkedField",
      "name": "presetComments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "graderHint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studentFeedback",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RubricSingle",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "allSubsections",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Rubric",
  "abstractKey": null
};
})();

(node as any).hash = "97ff6c2f144cfaf152bf22d4c59241ed";

export default node;
