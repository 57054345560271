/**
 * @generated SignedSource<<a0aab041f7149e8c08024baff2318538>>
 * @relayHash 79ea317bd2327cb0b2fa709166577637
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 79ea317bd2327cb0b2fa709166577637

import { ConcreteRequest, Query } from 'relay-runtime';
export type CourseRole = "NONE" | "PROCTOR" | "PROFESSOR" | "STAFF" | "STUDENT";
export type submissionsAuditRoleQuery$variables = {
  examId: string;
};
export type submissionsAuditRoleQuery$data = {
  readonly me: {
    readonly role: CourseRole;
  };
};
export type submissionsAuditRoleQuery = {
  response: submissionsAuditRoleQuery$data;
  variables: submissionsAuditRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "examId",
      "variableName": "examId"
    }
  ],
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsAuditRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsAuditRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "79ea317bd2327cb0b2fa709166577637",
    "metadata": {},
    "name": "submissionsAuditRoleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f25747852406a5301de3b4581431429c";

export default node;
