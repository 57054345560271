/**
 * @generated SignedSource<<a2e1fb01364efa31e077d872323269f3>>
 * @relayHash 484aae187d8907b4ece1ad1d35770af3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 484aae187d8907b4ece1ad1d35770af3

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RubricDirection = "credit" | "deduction";
export type DestroyPresetCommentInput = {
  clientMutationId?: string | null;
  presetCommentId: string;
};
export type RubricDestroyPresetCommentMutation$variables = {
  input: DestroyPresetCommentInput;
};
export type RubricDestroyPresetCommentMutation$data = {
  readonly destroyPresetComment: {
    readonly rubric: {
      readonly id: string;
      readonly rubricPreset: {
        readonly direction: RubricDirection;
        readonly id: string;
        readonly label: string | null;
        readonly mercy: number | null;
        readonly presetComments: ReadonlyArray<{
          readonly graderHint: string;
          readonly id: string;
          readonly label: string | null;
          readonly order: number | null;
          readonly points: number;
          readonly studentFeedback: string | null;
        }>;
      } | null;
    };
  } | null;
};
export type RubricDestroyPresetCommentMutation = {
  response: RubricDestroyPresetCommentMutation$data;
  variables: RubricDestroyPresetCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DestroyPresetCommentPayload",
    "kind": "LinkedField",
    "name": "destroyPresetComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rubric",
        "kind": "LinkedField",
        "name": "rubric",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RubricPreset",
            "kind": "LinkedField",
            "name": "rubricPreset",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mercy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PresetComment",
                "kind": "LinkedField",
                "name": "presetComments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "points",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "graderHint",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studentFeedback",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RubricDestroyPresetCommentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RubricDestroyPresetCommentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "484aae187d8907b4ece1ad1d35770af3",
    "metadata": {},
    "name": "RubricDestroyPresetCommentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "297044306d857c32316bacc3d5159c92";

export default node;
