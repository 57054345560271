/**
 * @generated SignedSource<<14f235db87332e504ceb21c09f9af84d>>
 * @relayHash d049d680a7c790623232a70eb4962b0b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d049d680a7c790623232a70eb4962b0b

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReleaseGradingLockInput = {
  clientMutationId?: string | null;
  markComplete?: boolean | null;
  pnum: number;
  qnum: number;
  registrationId: string;
};
export type gradingReleaseLockMutation$variables = {
  input: ReleaseGradingLockInput;
};
export type gradingReleaseLockMutation$data = {
  readonly releaseGradingLock: {
    readonly gradingLock: {
      readonly grader: {
        readonly displayName: string;
      } | null;
      readonly id: string;
    };
    readonly released: boolean;
  } | null;
};
export type gradingReleaseLockMutation = {
  response: gradingReleaseLockMutation$data;
  variables: gradingReleaseLockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "released",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingReleaseLockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseGradingLockPayload",
        "kind": "LinkedField",
        "name": "releaseGradingLock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GradingLock",
            "kind": "LinkedField",
            "name": "gradingLock",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "grader",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingReleaseLockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseGradingLockPayload",
        "kind": "LinkedField",
        "name": "releaseGradingLock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GradingLock",
            "kind": "LinkedField",
            "name": "gradingLock",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "grader",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d049d680a7c790623232a70eb4962b0b",
    "metadata": {},
    "name": "gradingReleaseLockMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "db841007f263fa18b6b90f54ecb753be";

export default node;
