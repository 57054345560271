/**
 * @generated SignedSource<<f043814a76415e6e36f790098a6a7dab>>
 * @relayHash 019b39225e6efaa57606461fd98109f9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 019b39225e6efaa57606461fd98109f9

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type examsNewAnomalySubscription$variables = {
  examId: string;
};
export type examsNewAnomalySubscription$data = {
  readonly anomalyWasCreated: {
    readonly anomaly: {
      readonly " $fragmentSpreads": FragmentRefs<"exams_anomaly">;
    };
    readonly anomalyEdge: {
      readonly node: {
        readonly id: string;
      } | null;
    };
  };
};
export type examsNewAnomalySubscription = {
  response: examsNewAnomalySubscription$data;
  variables: examsNewAnomalySubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "examId",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AnomalyEdge",
  "kind": "LinkedField",
  "name": "anomalyEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Anomaly",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsNewAnomalySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnomalyWasCreatedPayload",
        "kind": "LinkedField",
        "name": "anomalyWasCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Anomaly",
            "kind": "LinkedField",
            "name": "anomaly",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "exams_anomaly"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsNewAnomalySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnomalyWasCreatedPayload",
        "kind": "LinkedField",
        "name": "anomalyWasCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Anomaly",
            "kind": "LinkedField",
            "name": "anomaly",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priorAnomalyCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "final",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "019b39225e6efaa57606461fd98109f9",
    "metadata": {},
    "name": "examsNewAnomalySubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "43e5b1f0fc19d083e726cd21a4efa5d1";

export default node;
