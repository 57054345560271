/**
 * @generated SignedSource<<2e4bf6056eb13b0ee8318055b051bdc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exams_messages$data = {
  readonly examAnnouncements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly id: string;
      } | null;
    } | null> | null;
  };
  readonly id: string;
  readonly messages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly id: string;
        readonly registration: {
          readonly examVersion: {
            readonly id: string;
          };
          readonly id: string;
          readonly room: {
            readonly id: string;
          } | null;
          readonly user: {
            readonly displayName: string;
            readonly id: string;
          };
        };
        readonly sender: {
          readonly displayName: string;
          readonly isMe: boolean;
        };
      } | null;
    } | null> | null;
  };
  readonly roomAnnouncements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly id: string;
        readonly room: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
  readonly studentQuestions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly id: string;
        readonly registration: {
          readonly examVersion: {
            readonly id: string;
          };
          readonly id: string;
          readonly room: {
            readonly id: string;
          } | null;
          readonly user: {
            readonly displayName: string;
            readonly id: string;
          };
        };
      } | null;
    } | null> | null;
  };
  readonly versionAnnouncements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly createdAt: string;
        readonly examVersion: {
          readonly id: string;
          readonly name: string;
        };
        readonly id: string;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "exams_messages";
};
export type exams_messages$key = {
  readonly " $data"?: exams_messages$data;
  readonly " $fragmentSpreads": FragmentRefs<"exams_messages">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v0/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Registration",
  "kind": "LinkedField",
  "name": "registration",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "room",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamVersion",
      "kind": "LinkedField",
      "name": "examVersion",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "versionAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "roomAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "examAnnouncements"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "studentQuestions"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "messages"
        ]
      }
    ]
  },
  "name": "exams_messages",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "versionAnnouncements",
      "args": null,
      "concreteType": "VersionAnnouncementConnection",
      "kind": "LinkedField",
      "name": "__Exam_versionAnnouncements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VersionAnnouncementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VersionAnnouncement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExamVersion",
                  "kind": "LinkedField",
                  "name": "examVersion",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "roomAnnouncements",
      "args": null,
      "concreteType": "RoomAnnouncementConnection",
      "kind": "LinkedField",
      "name": "__Exam_roomAnnouncements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoomAnnouncementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoomAnnouncement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Room",
                  "kind": "LinkedField",
                  "name": "room",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "examAnnouncements",
      "args": null,
      "concreteType": "ExamAnnouncementConnection",
      "kind": "LinkedField",
      "name": "__Exam_examAnnouncements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamAnnouncementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamAnnouncement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "studentQuestions",
      "args": null,
      "concreteType": "StudentQuestionConnection",
      "kind": "LinkedField",
      "name": "__Exam_studentQuestions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentQuestionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudentQuestion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v9/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "messages",
      "args": null,
      "concreteType": "MessageConnection",
      "kind": "LinkedField",
      "name": "__Exam_messages_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Message",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "sender",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isMe",
                      "storageKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                (v9/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "304e890ef9f9f311351fabb17f234208";

export default node;
