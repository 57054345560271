/**
 * @generated SignedSource<<d764af9fd83f02d9d6bc18aaa07e4519>>
 * @relayHash 386d159f17c967da72b82a61bfa485e3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 386d159f17c967da72b82a61bfa485e3

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HtmlTag = "HTML";
export type statsExamQuery$variables = {
  examId: string;
};
export type statsExamQuery$data = {
  readonly exam: {
    readonly course: {
      readonly id: string;
      readonly title: string;
    };
    readonly examVersions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly currentScores: ReadonlyArray<{
            readonly registration: {
              readonly id: string;
              readonly started: boolean;
              readonly user: {
                readonly displayName: string;
              };
            };
            readonly scores: ReadonlyArray<ReadonlyArray<number>>;
          }> | null;
          readonly dbQuestions: ReadonlyArray<{
            readonly extraCredit: boolean;
            readonly name: {
              readonly type: HtmlTag;
              readonly value: string;
            } | null;
            readonly parts: ReadonlyArray<{
              readonly extraCredit: boolean;
              readonly name: {
                readonly type: HtmlTag;
                readonly value: string;
              } | null;
              readonly points: number;
            }>;
          }>;
          readonly id: string;
          readonly name: string;
          readonly qpPairs: ReadonlyArray<{
            readonly pnum: number;
            readonly qnum: number;
          }>;
          readonly " $fragmentSpreads": FragmentRefs<"RubricPresetHistograms">;
        } | null;
      } | null> | null;
    };
    readonly id: string;
    readonly name: string;
  };
};
export type statsExamQuery = {
  response: statsExamQuery$data;
  variables: statsExamQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Course",
  "kind": "LinkedField",
  "name": "course",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qnum",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pnum",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "QpPair",
  "kind": "LinkedField",
  "name": "qpPairs",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraCredit",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "started",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scores",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Html",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graderHint",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "studentFeedback",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "RubricPreset",
  "kind": "LinkedField",
  "name": "rubricPreset",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mercy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PresetComment",
      "kind": "LinkedField",
      "name": "presetComments",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v23/*: any*/),
        (v21/*: any*/),
        (v12/*: any*/),
        (v24/*: any*/),
        (v25/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = [
  (v2/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "subsections",
  "plural": true,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "Rubric",
  "kind": "LinkedField",
  "name": "rootRubric",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    (v21/*: any*/),
    (v12/*: any*/),
    (v22/*: any*/),
    (v26/*: any*/),
    (v28/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Rubric",
      "kind": "LinkedField",
      "name": "allSubsections",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v8/*: any*/),
        (v21/*: any*/),
        (v12/*: any*/),
        (v22/*: any*/),
        (v26/*: any*/),
        (v28/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "statsExamQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "examVersions",
            "args": null,
            "concreteType": "ExamVersionConnection",
            "kind": "LinkedField",
            "name": "__Exam_examVersions_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamVersion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Question",
                        "kind": "LinkedField",
                        "name": "dbQuestions",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Part",
                            "kind": "LinkedField",
                            "name": "parts",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RubricPresetHistograms"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RegistrationScore",
                        "kind": "LinkedField",
                        "name": "currentScores",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Registration",
                            "kind": "LinkedField",
                            "name": "registration",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "statsExamQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v19/*: any*/),
            "concreteType": "ExamVersionConnection",
            "kind": "LinkedField",
            "name": "examVersions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamVersion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Question",
                        "kind": "LinkedField",
                        "name": "dbQuestions",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Part",
                            "kind": "LinkedField",
                            "name": "parts",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v2/*: any*/),
                              (v20/*: any*/),
                              (v29/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BodyItem",
                                "kind": "LinkedField",
                                "name": "bodyItems",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  (v20/*: any*/),
                                  (v29/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v20/*: any*/),
                          (v29/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Registration",
                        "kind": "LinkedField",
                        "name": "registrations",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GradingComment",
                            "kind": "LinkedField",
                            "name": "allGradingComments",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "message",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bnum",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PresetComment",
                                "kind": "LinkedField",
                                "name": "presetComment",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v21/*: any*/),
                                  (v12/*: any*/),
                                  (v24/*: any*/),
                                  (v25/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RubricPreset",
                                    "kind": "LinkedField",
                                    "name": "rubricPreset",
                                    "plural": false,
                                    "selections": (v27/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RegistrationScore",
                        "kind": "LinkedField",
                        "name": "currentScores",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Registration",
                            "kind": "LinkedField",
                            "name": "registration",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": "examVersions(first:100)"
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_examVersions",
            "kind": "LinkedHandle",
            "name": "examVersions"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "386d159f17c967da72b82a61bfa485e3",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "exam",
            "examVersions"
          ]
        }
      ]
    },
    "name": "statsExamQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "40b12c2a39dc52b8554f86071501931f";

export default node;
