/**
 * @generated SignedSource<<13855625e348cddf6234eef1e92fd285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HtmlTag = "HTML";
export type ReferenceType = "dir" | "file";
import { FragmentRefs } from "relay-runtime";
export type PartShow$data = {
  readonly bodyItems: ReadonlyArray<{
    readonly id: string;
    readonly info: any;
    readonly " $fragmentSpreads": FragmentRefs<"DisplayBody">;
  }>;
  readonly description: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly extraCredit: boolean;
  readonly id: string;
  readonly name: {
    readonly type: HtmlTag;
    readonly value: string;
  } | null;
  readonly points: number;
  readonly references: ReadonlyArray<{
    readonly path: string;
    readonly type: ReferenceType;
  }>;
  readonly rootRubric?: {
    readonly " $fragmentSpreads": FragmentRefs<"ShowRubricKey">;
  };
  readonly " $fragmentType": "PartShow";
};
export type PartShow$key = {
  readonly " $data"?: PartShow$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartShow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "withRubric"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartShow",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Html",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraCredit",
      "storageKey": null
    },
    {
      "condition": "withRubric",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rootRubric",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowRubricKey"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reference",
      "kind": "LinkedField",
      "name": "references",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BodyItem",
      "kind": "LinkedField",
      "name": "bodyItems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "info",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DisplayBody"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Part",
  "abstractKey": null
};
})();

(node as any).hash = "ec5cc2db2e5e24fe11813811c7a3d1fe";

export default node;
