/**
 * @generated SignedSource<<a6f1ea7b4da37ec8b5f9d6c24c7b753e>>
 * @relayHash e8eb6c314164ae6ea596d25104d953bd
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e8eb6c314164ae6ea596d25104d953bd

import { ConcreteRequest, Query } from 'relay-runtime';
export type submissionsExportStudentSnapshotsQuery$variables = {
  regId: string;
};
export type submissionsExportStudentSnapshotsQuery$data = {
  readonly registration: {
    readonly id: string;
    readonly snapshots: ReadonlyArray<{
      readonly answers: any;
      readonly createdAt: string;
    }> | null;
  } | null;
};
export type submissionsExportStudentSnapshotsQuery = {
  response: submissionsExportStudentSnapshotsQuery$data;
  variables: submissionsExportStudentSnapshotsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "regId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answers",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsExportStudentSnapshotsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Snapshot",
            "kind": "LinkedField",
            "name": "snapshots",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsExportStudentSnapshotsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Registration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Snapshot",
            "kind": "LinkedField",
            "name": "snapshots",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "e8eb6c314164ae6ea596d25104d953bd",
    "metadata": {},
    "name": "submissionsExportStudentSnapshotsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "34d8fb5fe2ce712a0c8ded2e9f1d5ba2";

export default node;
