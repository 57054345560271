/**
 * @generated SignedSource<<bcb4ab916e6aa18c844be681a99dc64e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamSubmitted$data = {
  readonly myRegistration: {
    readonly lastSnapshot: string | null;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "ExamSubmitted";
};
export type ExamSubmitted$key = {
  readonly " $data"?: ExamSubmitted$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamSubmitted">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamSubmitted",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "myRegistration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastSnapshot",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};

(node as any).hash = "eaae694bbb4ea8985068a5c46bab7ce4";

export default node;
